<template>
  <div class="player-wrapper">
    <div v-if="loading" class="load-container"><div class="loader"></div></div>
    <div data-vjs-player ref="videoParent">
      <video class="video-js vjs-default-skin" preload="none" ref="video"></video>
      <div
        @mousedown="handleMouseDown"
        @mouseup="handleMouseUp"
        @mousemove="handleMouseMove"
        @dblclick="toggleFullScreen"
        @mouseover="mouseEnter"
        @mouseleave="mouseLeave"
        @touchstart="touchStart"
        @touchend="touchEnd"
        class="custom-ui"
      >
        <slot></slot>
        <div class="endcard" v-if="endcardVisible">
          <div class="endcard-content">
            <h2>Конец архива</h2>
          </div>
        </div>
        <div class="tl">
          <TimelineNew
            @click.stop=""
            @dblclick.stop=""
            @timeline-ready="timelineReady"
            ref="timeline"
            :url="url"
            :source="source"
            @seek="seek"
            @seek-start="seekStart"
            @seek-end="seekEnd"
            :controlWidth="controlWidth"
            :date="dateFormatted"
            :camId="camName"
            :uuid="uuid"
            @loading="loading = $event"
          />
        </div>
        <Transition name="slideUp-fade">
          <div
            v-show="(showControls || videoZoom.isTouchscreen) && playerMounted"
            class="controls"
            ref="controlsRef"
          >
            <div class="controls-left" ref="controlsLeftRef">
              <button
                @click.stop="togglePlay"
                class="mdi"
                :class="{ 'mdi-pause': !paused, 'mdi-play': paused }"
              ></button>
              <span class="cam-name">{{ camName }}</span>
              <VueDatePicker
                class="date-picker"
                format="dd.MM.yyyy"
                locale="ru-RU"
                dark
                teleport-center
                v-model="date"
                @update:model-value="dateChange"
                ref="datePicker"
                :allowed-dates="allowedDays"
                :enable-time-picker="false"
                auto-apply
                :clearable="false"
              >
                <template #dp-input="{ value }">
                  <span class="date-picker-value">{{ value }}</span>
                </template>
              </VueDatePicker>
              <button @click.stop="screenshot" class="screenshot" title="Сделать скриншот">
                <span class="mdi mdi-camera"></span>
              </button>
              <button @click.stop="openDownloadWindow" title="Скачать архив">
                <span class="mdi mdi-download"></span>
              </button>
              <button
                title="Показать/скрыть пропуски"
                v-if="store.isAdmin"
                @click.stop="store.setShowGaps(!store.showGaps)"
                class="mdi"
                :class="{ 'mdi-eye-off': !store.showGaps, 'mdi-eye': store.showGaps }"
              ></button>
              <div class="playback-speed">
                <button @click.stop="showPlaybackSpeed = !showPlaybackSpeed" title="Скорость воспроизведения">
                  {{ playbackSpeed.label }}
                </button>
                <Transition name="slideUp-fade">
                  <div v-if="showPlaybackSpeed" class="playback-speed-menu">
                    <ul ref="playbackSpeedMenu">
                      <li
                        @click.stop="setPlaybackSpeed(speed.speed)"
                        :class="{ active: speed.active }"
                        v-for="speed in playbackSpeeds"
                        :key="speed.label"
                      >
                        {{ speed.label }}
                      </li>
                    </ul>
                  </div>
                </Transition>
              </div>
              <div class="zoom" v-if="!videoZoom.isTouchscreen">
                <div class="zoom-selected" @click.stop="toggleZoom" title="Увеличить/уменьшить">
                  <span class="mdi mdi-magnify-expand"></span>
                </div>
                <div class="zoom-slider">
                  <Transition name="slide-fade">
                    <vue-slider
                      v-show="zoomShow"
                      v-model="videoZoom.scale"
                      direction="ltr"
                      width="80px"
                      :interval="0.01"
                      :min="1"
                      :max="3"
                      style=""
                    ></vue-slider>
                  </Transition>
                </div>
              </div>
            </div>
            <div class="controls-right" ref="controlsRightRef">
              <div class="quality-levels">
                <button
                  @click.stop="showQualityLevels = !showQualityLevels"
                  class="mdi mdi-cog-box"
                  title="Качество видео"
                ></button>
                <Transition name="slideUp-fade">
                  <div v-if="showQualityLevels" class="quality-menu">
                    <ul ref="qualityMenu">
                      <li
                        @click.stop="setQuality(quality.resolution)"
                        :class="{ active: quality.active }"
                        v-for="quality in qualityLevels"
                        :key="quality.label"
                      >
                        {{ quality.label }}
                      </li>
                    </ul>
                  </div>
                </Transition>
              </div>
              <button @click.stop="toggleFullScreen" class="mdi mdi-fullscreen fullscreen" title="Полноэкранный режим"></button>
            </div>
          </div>
        </Transition>
        <span v-if="showTimer || true" class="time">{{ time() }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { shallowRef, ref, onMounted, onUnmounted } from 'vue'
import videojs from 'video.js'
import { eventsMap, events } from './events'
import moment from 'moment'
import { useDefaultStore } from '@/store'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import { lockOrientationUniversal } from '@/helpers/utils'
import { VideoZoom } from './videoZoom'
import TimelineNew from './TimelineNew.vue'
import VueDatePicker from '@vuepic/vue-datepicker'
import axios from 'axios'
import { inject } from 'vue'
import ArchiveIntervalDownload from '@/components/ArchiveIntervalDownload.vue'
import ArchiveIntervalDownloadNew from '@/components/ArchiveIntervalDownloadNew.vue'
import DownloadLocationSelect from '@/components/ArchiveDownload/DownloadLocationSelect.vue'
import { checkVersion } from '@/helpers/utils'
export default {
  name: 'CamPlayerArchive',
  components: {
    VueSlider,
    TimelineNew,
    VueDatePicker
  },
  setup() {
    const player = shallowRef({})
    const store = useDefaultStore()
    const videoZoom = ref(new VideoZoom())
    const controlWidth = ref(0)
    const controlsRef = ref(null)
    const controlsLeftRef = ref(null)
    const controlsRightRef = ref(null)
    const timeline = ref(null)
    const { showModal, hideModal } = inject('modal')
    const updateControlWidth = () => {
      if (timeline.value) timeline.value.handleResize()
      if (controlsRef.value && controlsLeftRef.value && controlsRightRef.value) {
        controlWidth.value =
          controlsRef.value.offsetWidth -
          controlsLeftRef.value.offsetWidth -
          controlsRightRef.value.offsetWidth
      }
    }

    onMounted(() => {
      updateControlWidth()
      window.addEventListener('resize', updateControlWidth)
    })

    onUnmounted(() => {
      window.removeEventListener('resize', updateControlWidth)
    })

    return {
      player,
      store,
      videoZoom,
      timeline,
      controlWidth,
      controlsRef,
      controlsLeftRef,
      controlsRightRef,
      updateControlWidth,
      showModal,
      hideModal
    }
  },
  props: {
    camName: {
      type: String,
      required: true
    },
    showTimer: {
      type: Boolean,
      default: true
    }
  },
  emits: ['screenshot', 'touchStart', 'touchEnd', 'update:modelValue'],
  data() {
    return {
      timestamp: null,
      playerMounted: false,
      paused: false,
      endcardVisible: false,
      internalPaused: false,
      hoverTimeout: null,
      date: null,
      dotColor: '#FF0000',
      showControls: false,
      totalPlayedTime: 0,
      oldTime: 0,
      timeSinceLastSegment: 0,
      video: null,
      videoWidth: 0,
      currentUrl: null,
      loading: false,
      range: [0, 84399],
      videoHeight: 0,
      mouseDown: false,
      mouseX: 0,
      mouseY: 0,
      mouseXStart: 0,
      mouseYStart: 0,
      zoomShow: false,
      showQualityLevels: false,
      showPlaybackSpeed: false,
      source: this.startSource,
      timeCounter: 0,
      currentTime: 0,
      url:
        window.location.protocol === 'http:'
          ? `http://${this.store.currentDivision.address}`
          : `https://${this.store.currentDivision.url}`,
      allowedDays: [],
      timeToSeek: 0,
      isSyncEnabled: false,
      playbackSpeeds: [
        {
          speed: 1,
          active: true,
          label: '1x'
        },
        {
          speed: 1.5,
          active: false,
          label: '1.5x'
        },
        {
          speed: 2,
          active: false,
          label: '2x'
        },
        {
          speed: 2.5,
          active: false,
          label: '2.5x'
        },
        {
          speed: 3,
          active: false,
          label: '3x'
        }
      ],
      playbackSpeed: {
        speed: 1,
        active: true,
        label: '1x'
      },
      qualityLevels: [
        {
          resolution: 360,
          active: false,
          label: '360p'
        },
        {
          resolution: 480,
          active: false,
          label: '480p'
        },
        {
          resolution: 720,
          active: false,
          label: '720p'
        },
        {
          resolution: 1080,
          active: false,
          label: '1080p'
        },
        {
          resolution: 'original',
          active: true,
          label: 'Original'
        }
      ],
      selectedPlaylist: null
    }
  },

  watch: {
    video: {
      handler(to) {
        this.videoWidth = to.videoWidth
        this.videoHeight = to.videoHeight
      },
      deep: true
    },
    date(newVal) {
      axios
        .get(
          `${this.url}/api/getAvailableRange/${this.camName}/${moment(newVal).format('YYYY_MM_D')}`
        )
        .then((response) => {
          this.range = response.data
        })
        .catch((error) => {
          console.error('Error fetching available range:', error)
        })
    },
    timelineTime(newVal) {
      if (newVal) {
        //this.$refs.timeline.setTimelinePosition(newVal,false);
      }
    },
    time() {
      if (!this.$refs.timeline.mouseDown) {
        //this.$refs.timeline.setTimelinePosition(moment(this.timestamp).add(this.timeSinceLastSegment, 'seconds').toDate(), false);
      }
    }
  },
  computed: {
    timelineTime() {
      if (this.timestamp) {
        return moment(this.timestamp).add(this.timeSinceLastSegment, 'seconds').toDate()
      } else {
        return null
      }
    },
    selectedQuality() {
      const active = this.qualityLevels.find((q) => q.active)
      return active.resolution
    },
    dateFormatted() {
      return moment(this.date).format('YYYY_MM_D')
    },
    uuid() {
      return this.store.currentDivision.guid
    },
    aspectRatio() {
      if (!this.videoWidth || !this.videoHeight) {
        return 16 / 9
      }
      return this.videoWidth / this.videoHeight
    }
  },
  mounted() {
    const video = this.$refs.video
    var isAndroid = /(android)/i.test(navigator.userAgent)
    this.qualityLevels = this.qualityLevels.map((q) => {
      if (isAndroid) {
        if (q.resolution === 720) {
          q.active = true
        } else {
          q.active = false
        }
      } else {
        if (q.resolution === 'original') {
          q.active = true
        } else {
          q.active = false
        }
      }
      return q
    })
    const options = {
      autoplay: true,
      controls: false,
      muted: true,
      preload: 'none',
      enableSmoothSeeking: true,
      controlBar: {
        pictureInPictureToggle: false,
        bigPlayButton: false
      },
      html5: {
        vhs: {
          overrideNative: true,
          handlePartialData: true
        }
      },
      fluid: true,
      sources: [
        {
          src: ''
        }
      ]
    }
    this.player.value = videojs(video, options, this.playerReady)
    window.video = this.player.value
    window.player = this
    videojs.addLanguage('en', { 'No compatible source was found for this media.': 'Загрузка...' })
  },
  unmounted() {
    events.forEach((eventName) => {
      const eventFunc = eventsMap[eventName]
      if (eventFunc in this) {
        this.player.value.off(eventName, this[eventFunc])
      }
    })
    this.videoObserver && this.videoObserver.disconnect()
    this.player.value.dispose()
    this.videoZoom.dispose()
  },
  methods: {
    playerReady() {
      events.forEach((eventName) => {
        const eventFunc = eventsMap[eventName]
        if (eventFunc in this) {
          this.player.value.on(eventName, this[eventFunc])
        }
      })
      this.player.value.reloadSourceOnError()
      this.video = this.player.value.el().querySelector('video')
      this.playerMounted = true
      this.showControls = true
      this.videoZoom.init(this.$refs.videoParent, this.video)
      this.videoZoom.addEventHandler('swipe', this.handleSwipe)
    },
    onLoadedMetadata() {
      this.player.value.currentTime(this.timeToSeek)
      this.handleTimeUpdate()
      this.player.value.playbackRate(this.playbackSpeed.speed)
    },
    async setNewPlaylist() {
      const uuid = this.store.currentDivision.guid
      const quality = this.selectedQuality
      const playlistUrl = `${this.url}/${uuid}/archive/${moment(this.date).format('YYYY_MM_D')}/${this.camName}/${quality}/index.m3u8`
      this.timeToSeek = this.player.value.currentTime()
      this.player.value.src({ src: playlistUrl, type: 'application/x-mpegURL' })
    },
    async setQuality(quality) {
      this.qualityLevels = this.qualityLevels.map((q) => {
        if (q.resolution === quality) {
          q.active = true
          this.selectedQuality = quality
        } else {
          q.active = false
        }
        return q
      })
      await this.setNewPlaylist()
    },

    setPlaybackSpeed(speed) {
      this.playbackSpeeds = this.playbackSpeeds.map((s) => {
        if (s.speed === speed) {
          s.active = true
          this.playbackSpeed = s
        } else {
          s.active = false
        }
        return s
      })
      this.showPlaybackSpeed = false
      this.player.value.playbackRate(speed)
    },
    keepInInterval(min, max, value) {
      return Math.min(Math.max(value, min), max)
    },
    onResize() {
      this.videoHeight = this.video.videoHeight
      this.videoWidth = this.video.videoWidth
    },
    toggleFullScreen() {
      this.updateControlWidth()
      if (this.player.value.isFullscreen()) {
        this.player.value.exitFullscreen()
      } else {
        this.player.value.requestFullscreen()
      }
    },
    screenshot() {
      const video = this.player.value.el().querySelector('video')
      const canvas = document.createElement('canvas')
      canvas.style.position = 'absolute'
      canvas.width = video.videoWidth
      canvas.height = video.videoHeight

      const ctx_draw = canvas.getContext('2d')
      ctx_draw.drawImage(video, 0, 0)
      this.$emit('screenshot', canvas.toDataURL())
    },
    mouseEnter() {
      clearTimeout(this.hoverTimeout)
      this.showControls = true
    },
    onFullscreenChange() {
      if (this.player.value.isFullscreen() && this.store.smallScreen) {
        lockOrientationUniversal('landscape')
      }
    },
    mouseLeave() {
      this.hoverTimeout = setTimeout(() => {
        this.showControls = false
      }, 1500)
    },
    openDownloadWindow() {
      const version = this.store.currentDivision.version
      const check = checkVersion(version, '1.0.0.88')
      const checkNew = checkVersion(version, '1.0.0.89')
      if (checkNew) {
        this.showModal(
          DownloadLocationSelect,
          { range: this.range, date: this.date },
          {},
          'Скачать архив'
        )
        return
      }
      if (check) {
        this.showModal(
          ArchiveIntervalDownloadNew,
          {
            range: this.range,
            maxInterval: [0, 84399],
            url: this.url,
            cam: this.camName,
            uuid: this.uuid,
            date: this.date,
            hide: this.hideModal
          },
          {},
          'Скачать архив'
        )
      } else {
        this.showModal(
          ArchiveIntervalDownload,
          {
            range: this.range,
            maxInterval: [0, 84399],
            url: this.url,
            cam: this.camName,
            uuid: this.uuid,
            date: this.date,
            hide: this.hideModal
          },
          {},
          'Скачать архив'
        )
      }
    },
    handleTimeUpdate() {
      const tracks = this.player.value.textTracks()
      const vm = this
      let segmentMetadataTrack
      for (let i = 0; i < tracks.length; i++) {
        if (tracks[i].label === 'segment-metadata') {
          segmentMetadataTrack = tracks[i]
        }
      }
      if (segmentMetadataTrack) {
        segmentMetadataTrack.on('cuechange', function () {
          let activeCue = segmentMetadataTrack.activeCues[0]
          if (activeCue) {
            if (activeCue.value.resolution) vm.setQuality(activeCue.value.resolution.height, false)
            const extractedDate = activeCue.value.uri
              .split('/')
              .pop()
              .split('-')
              .pop()
              .split('.')[0]
            let date = moment(extractedDate, 'YYYYMMDDHHmmss')

            if (extractedDate.length < 14) {
              date = moment()
            }
            vm.totalPlayedTime = vm.player.value.currentTime()
            vm.timeSinceLastSegment = 0
            vm.timestamp = date
          }
        })
      }
    },
    onTimeUpdate() {
      const pulse = this.player.value.currentTime() - this.currentTime
      const increase = Math.max(Math.min(pulse, 3), 0)
      if (this.isSyncEnabled) this.$refs.timeline.increaseCurrentTime(increase * 1000)
      this.currentTime = this.player.value.currentTime()
      this.timeCounter = this.player.value.currentTime() - this.totalPlayedTime
      this.timeSinceLastSegment = Math.floor(this.player.value.currentTime() - this.totalPlayedTime)
      //this.$refs.timeline.onProgress(this.player.value.currentTime() * 1000);
    },
    dateChange(date) {
      this.$refs.timeline.setTimelineToDate(date)
    },
    seek(e) {
      const uuid = this.store.currentDivision.guid
      const playlistUrl = `${this.url}/${uuid}/archive/${e.d}/${this.camName}/${this.selectedQuality}/index.m3u8`
      if (playlistUrl !== this.currentUrl) {
        this.currentUrl = playlistUrl
        this.date = moment(e.d, 'YYYY_MM_D').toDate()
        this.player.value.src({ src: playlistUrl, type: 'application/x-mpegURL' })
        this.timeToSeek = e.time
        this.isSyncEnabled = false
      }
      this.player.value.currentTime(e.time)
    },
    seekStart() {
      this.player.value.pause()
    },
    seekEnd() {
      if (!this.internalPaused) this.player.value.play()
    },
    togglePlay() {
      if (this.paused) {
        this.internalPaused = false
        this.player.value.play()
      } else {
        this.internalPaused = true
        this.player.value.pause()
      }
    },
    time() {
      if (this.$refs.timeline) {
        return moment(this.$refs.timeline.centerTime).format('DD.MM.YYYY, HH:mm:ss')
      } else {
        return ''
      }
    },
    touchStart(e) {
      this.$emit('touchStart', e)
    },
    touchEnd(e) {
      this.$emit('touchEnd', e)
    },
    onPlay() {
      this.paused = false
      this.dotColor = '#FF0000'
      const vm = this
      setTimeout(function () {
        vm.isSyncEnabled = true
      }, 1000)
    },
    onPause() {
      this.paused = true
      this.dotColor = '#EEEEEE'
      this.isSyncEnabled = false
    },
    onWaiting() {
      this.dotColor = '#EEEEEE'
    },
    onSeeking() {
      this.dotColor = '#EEEEEE'
    },
    onPlaying() {
      this.dotColor = '#FF0000'
      this.endcardVisible = false
    },
    onError() {
      if (this.player.value.error().code === 3) {
        this.player.value.error(null)
        const t = this.player.value.currentTime()
        this.player.value.currentTime(t + 1)
        this.player.value.play()
      }
    },
    onEmptied() {
      //e.target.querySelector(".vjs-error-display").hidden = true;
      //this.seek(this.player.value.currentTime());
      //window.emptyError = e;
      if (this.player.value.duration() === 0) {
        //alert("empty");
        return
      }
      if (this.player.value.currentTime() === this.player.value.duration())
        if (!this.$refs.timeline.seekToNextDay(this.date)) {
          this.player.value.pause();
          this.endcardVisible = true;
          return;
        }
      this.player.value.play()
      //this.player.value.play();
    },
    onStalled() {},
    onEnded() {
      if (this.player.value.currentTime() === this.player.value.duration())
        if (!this.$refs.timeline.seekToNextDay(this.date)) {
          this.player.value.pause();
          this.endcardVisible = true;
          return;
        }
      this.player.value.play()
    },
    toggleZoom() {
      this.zoomShow = !this.zoomShow
    },
    handleSwipe(e) {
      this.$emit('swipe', e)
    },
    createMasterPlaylist(url) {
      const playlistContent = `#EXT-X-STREAM-INF:BANDWIDTH=150000,RESOLUTION=1280x720,CODECS="avc1.42e00a,mp4a.40.2"\n${url}`
      const finalPlaylist = `#EXTM3U\n${playlistContent}`
      const blob = new Blob([finalPlaylist], { type: 'application/x-mpegURL' })
      const blobUrl = URL.createObjectURL(blob)

      return blobUrl
    },
    timelineReady(dates, time) {
      this.allowedDays = []
      dates.forEach((date) => {
        this.allowedDays.push(moment(date, 'YYYY_MM_D').toDate())
      })
      this.date = moment(time.d, 'YYYY_MM_D').toDate()
      const uuid = this.store.currentDivision.guid
      const playlistUrl = `${this.url}/${uuid}/archive/${time.d}/${this.camName}/${this.selectedQuality}/index.m3u8`
      this.player.value.src({ src: playlistUrl, type: 'application/x-mpegURL' })
      this.timeToSeek = time.time
    },
    playlistLoaded(playlistString) {
      const blob = new Blob([playlistString], { type: 'application/x-mpegURL' })
      this.player.value.src({ src: URL.createObjectURL(blob), type: blob.type })
      this.player.value.currentTime(this.oldTime)
    },
    handleMouseDown(event) {
      this.mouseXStart = event.clientX
      this.mouseYStart = event.clientY
      const timelineRect = this.$refs.timeline.$el.getBoundingClientRect()
      const controlsRect = this.$refs.controlsRef.getBoundingClientRect()

      const isMouseOnTimeline =
        event.clientX >= timelineRect.left &&
        event.clientX <= timelineRect.right &&
        event.clientY >= timelineRect.top &&
        event.clientY <= timelineRect.bottom
      const isMouseOnControls =
        event.clientX >= controlsRect.left &&
        event.clientX <= controlsRect.right &&
        event.clientY >= controlsRect.top &&
        event.clientY <= controlsRect.bottom
      if (!isMouseOnTimeline && !isMouseOnControls) {
        this.isMouseDown = true
      }
    },
    handleMouseMove(event) {
      if (this.isMouseDown) {
        this.mouseX = event.clientX
        this.mouseY = event.clientY
      }
    },
    handleMouseUp() {
      if (this.isMouseDown) {
        this.togglePlay()
      }
      this.isMouseDown = false
    }
  }
}
</script>
<style scoped>
.player-wrapper {
  /* width: 100%; */
  height: 100%;
  aspect-ratio: v-bind(aspectRatio);
}
video {
  object-fit: cover;
}
.custom-ui {
  position: absolute;
  top: 0;
  width: 100%;
  display: block;
  height: 100%;
  user-select: none;
  z-index: 2;
}
.controls {
  position: absolute;
  bottom: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  width: 100%;
  display: flex;
}

.controls-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.cam-name {
  color: white;
  display: none;
  margin: auto 6px auto 0;
}
.live {
  padding: 0;
  display: flex;
}

.live::before {
  content: '';
  display: block;
  width: 6px;
  transition: background-color 0.3s ease;
  height: 6px;
  border-radius: 50%;
  background-color: v-bind(dotColor);
  margin: auto;
}
.livetext {
  color: white;
  margin: auto;
  margin-left: 6px;
}
.mdi {
  font-size: 16px;
  color: white;
}
.time {
  position: absolute;
  top: 3%;
  right: 3%;
  color: white;
  font-size: 12px;
  text-shadow: black 1px 1px 3px;
}
.vjs-fullscreen .time {
  font-size: 18px;
}

.video-js {
  overflow: hidden;
}

.screenshot {
  margin-left: 6px;
}
.zoom {
  display: flex;
  font-size: 14px;
  padding: 1px;
  position: relative;
  flex-direction: row;
}
.zoom-selected {
  cursor: pointer;
}
.zoom-slider {
  margin: auto 10px;
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(-100px);
  opacity: 0;
}
.quality-levels {
  position: relative;
  margin: auto;
}
.quality-menu {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  right: 0;
  bottom: 100%;
  border-radius: 2px;
}
.quality-menu ul {
  display: flex;
  flex-direction: column;
  padding: 0;
  justify-content: center;
}

.quality-menu li {
  padding: 5px;
  cursor: pointer;
  margin: 0;
  text-align: center;
  font-size: 18px;
}
.quality-menu li.active {
  background: rgba(255, 255, 255, 0.5);
}
.quality-menu li:hover {
  background: rgba(255, 255, 255, 0.2);
}

.playback-speed {
  position: relative;
}

.playback-speed button {
  padding: 4px;
  cursor: pointer;
  margin: 0;
  text-align: center;
  font-size: 18px;
}

.playback-speed-menu {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  right: 0;
  bottom: 100%;
  border-radius: 2px;
}
.playback-speed-menu ul {
  display: flex;
  flex-direction: column;
  padding: 0;
  justify-content: center;
}

.playback-speed-menu li {
  padding: 5px;
  cursor: pointer;
  margin: 0;
  text-align: center;
  font-size: 18px;
}

.playback-speed-menu li.active {
  background: rgba(255, 255, 255, 0.5);
}

.playback-speed-menu li:hover {
  background: rgba(255, 255, 255, 0.2);
}

.slideUp-fade-enter-active {
  transition: all 0.1s ease-out;
}

.slideUp-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}

.slideUp-fade-enter-from,
.slideUp-fade-leave-to {
  transform: translateY(100px);
  opacity: 0;
}

.controls-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

.tl {
  width: 100%;
  position: absolute;
  bottom: 50px;
  display: flex;
  flex-grow: 1;
}
.date-picker {
  width: fit-content;
}
.date-picker-value {
  color: white;
  font-size: 16px;
  line-height: 23px;
}

.loader {
  width: 50px;
  aspect-ratio: 1;
  display: grid;
  border: 4px solid #0000;
  border-radius: 50%;
  border-right-color: #fff;
  animation: l15 1s infinite linear;
}
.loader::before,
.loader::after {
  content: '';
  grid-area: 1/1;
  margin: 2px;
  border: inherit;
  border-radius: 50%;
  animation: l15 2s infinite;
}
.loader::after {
  margin: 8px;
  animation-duration: 3s;
}
@keyframes l15 {
  100% {
    transform: rotate(1turn);
  }
}

.load-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
}

@media (min-width: 768px) {
  .cam-name {
    display: block;
  }
  .mdi {
    font-size: 24px;
  }
  .date-picker-value {
    font-size: 18px;
  }
}
.endcard {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
}
.endcard-content {
  margin: auto;
}
.endcard-content h2 {
  text-transform: uppercase;
  font-family: monospace;
  font-size: 14px;
  height: 100%;
  text-shadow: 1px 1px 10px black;
}
</style>
