<template>
    <div class="downloads-list">
        <div v-if="list.length === 0">
            <div class="text-center">
                <span class="spinner-border spinner-border-xl"></span>
            </div>
        </div>
        <component :is="item.RecordType == 'Device' ? DownloadListItemDevice : DownloadListItemCloud"
         v-for="item in list" :key="item.uuid" :item="item" :url="url" />
    </div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { useDefaultStore } from "@/store";
import DownloadListItemDevice from './DownloadListItemDevice.vue';
import DownloadListItemCloud from './DownloadListItemCloud.vue';


const store = useDefaultStore();
const division = computed(() => store.currentDivision);

let es = ref(null);

const list = ref([]);

const url = ref("");

onMounted(async () => {
    url.value = window.location.protocol === 'http:' ? `http://${division.value.address}` : `https://${division.value.url}`;
    es.value = new EventSource(`${url.value}/api/concatToCloudAllSSE`);
    es.value.onmessage = (event) => {
        list.value = JSON.parse(event.data);

    }
})
onBeforeUnmount(() => {
    es.value.close();
})
</script>

