<template>
  <div class="download-list-item-device">
    <span class="name" :title="item.Name">{{ name }}</span>
    <span class="progress-container">
      <CProgress class="m-0 position-relative">
        <CProgressBar :color="progressColor" :value="item.Progress" class="progress-text">
          <span>{{ progressText }}</span>
          <span v-if="item.Progress !== 100" class="spinner-border spinner-border-sm"></span>
        </CProgressBar>
      </CProgress>
    </span>
    <span v-if="item.Status === 'Completed'"
      ><CButton color="primary" @click="copyLink()"><i class="mdi mdi-link"></i></CButton></span
    >
  </div>
</template>

<script setup>
import { computed, defineProps } from 'vue'
import { CProgress, CProgressBar, CButton } from '@coreui/bootstrap-vue'
import moment from 'moment'
import { useToast } from 'vue-toast-notification'

const props = defineProps({
  item: Object,
  url: {
    type: String,
    default: ''
  }
})
const progressColor = computed(() => {
  if (props.item.Status.includes('Completed')) return 'success'
  if (props.item.Status.includes('Error')) return 'danger'
  return 'success'
})
const progressText = computed(() => {
  if (props.item.Status.includes('Completed')) return 'Готов'
  if (props.item.Status.includes('Error')) return props.item.Status
  if (props.item.Status.includes('Ждём')) return props.item.Status
  return Math.floor(props.item.Progress) + '%'
})

const name = computed(() => {
  const cam = props.item.Name.split('_')[0]
  const date = moment(props.item.DateFrom).format('DD.MM.YYYY')
  const timeFrom = moment(props.item.DateFrom).format('HH:mm:ss')
  const timeTo = moment(props.item.DateTo).format('HH:mm:ss')
  return `${cam} ${date} ${timeFrom}-${timeTo}`
})

function copyLink() {
  const toast = useToast()
  navigator.clipboard.writeText(props.item.PublicUrl)
  toast.success('Ссылка скопирована')
}
</script>

<style scoped>
.download-list-item-device {
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  justify-content: space-between;
  margin-bottom: 5px;
}
.name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  line-height: 31px;
}
.progress-container {
  width: 100%;
  margin: auto;
  padding: 0 10px;
}
.bg-success {
  background-color: #57bb00 !important;
}
.progress-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
.download-checkbox input {
  position: absolute;
  left: -9999px;
  opacity: 0;
}
.download-checkbox label {
  white-space: nowrap;
  padding-left: 22px;
}
.spinner-border-sm {
  min-width: 1rem;
  min-height: 1rem;
}
.download-checkbox label::before {
  margin-top: 3px;
}
</style>
