<template>
  <div v-if="user" id="admin-user-edit" class="scroll-block">
    <form>
      <div class="row">
        <div class="col-12">
          <CNav variant="tabs" role="tablist">
            <CNavItem>
              <CNavLink
                href="javascript:void(0);"
                :active="tabPaneActiveKey === 1"
                @click="
                  () => {
                    tabPaneActiveKey = 1
                  }
                "
              >
                Общие
              </CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink
                href="javascript:void(0);"
                :active="tabPaneActiveKey === 2"
                @click="
                  () => {
                    tabPaneActiveKey = 2
                  }
                "
              >
                Доступные камеры
              </CNavLink>
            </CNavItem>
          </CNav>
          <CTabContent>
            <CTabPane role="tabpanel" aria-labelledby="home-tab" :visible="tabPaneActiveKey === 1">
              <div class="row">
                <div class="col-12">
                  <div class="box">
                    <div class="box-body">
                      <div class="box-header border-bottom border-dark">
                        <div class="box-title">
                          <span class="mdi mdi-account-circle mdi-24px"></span>
                        </div>
                      </div>
                      <div class="box-body">
                        <div class="row">
                          <div class="col-md-12 col-lg-6">
                            <CFormLabel for="usernoautofill">Логин</CFormLabel>
                            <CInputGroup class="mb-3">
                              <CInputGroupText><i class="mdi mdi-account"></i></CInputGroupText>
                              <CFormInput
                                id="usernoautofill"
                                name="unoautofull"
                                :disabled="!register && afterRegister"
                                v-model="user.user"
                                autocomplete="off"
                              />
                            </CInputGroup>
                          </div>

                          <div class="col-md-12 col-lg-6">
                            <CFormLabel for="passwordnoautofill">Пароль</CFormLabel>
                            <CInputGroup class="mb-3">
                              <CInputGroupText
                                ><i class="mdi mdi-form-textbox-password"></i
                              ></CInputGroupText>
                              <CFormInput
                                id="passwordnoautofill"
                                name="pnoautofull"
                                v-model="user.password"
                                :disabled="lockedPass"
                                :type="showPass ? 'text' : 'password'"
                                autocomplete="off"
                              />
                              <CInputGroupText @click="toggleShowPass">
                                <i v-if="showPass" class="mdi mdi-eye-off"></i>
                                <i v-else class="mdi mdi-eye"></i>
                              </CInputGroupText>
                            </CInputGroup>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-md-12 col-lg-6">
                            <CFormLabel for="emailnoautofill">Email</CFormLabel>
                            <CInputGroup class="mb-3">
                              <CInputGroupText><i class="mdi mdi-email"></i></CInputGroupText>
                              <CFormInput
                                id="emailnoautofill"
                                v-model="user.email"
                                autocomplete="off"
                              />
                            </CInputGroup>
                          </div>

                          <div class="col-md-12 col-lg-6">
                            <CFormLabel for="phonenoautofill">Телефон</CFormLabel>
                            <CInputGroup class="mb-3">
                              <CInputGroupText><i class="mdi mdi-email"></i></CInputGroupText>
                              <CFormInput
                                id="phonenoautofill"
                                v-model="user.phone"
                                autocomplete="off"
                              />
                            </CInputGroup>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-md-12 col-lg-6">
                            <CFormLabel for="role">Права</CFormLabel>
                            <CInputGroup class="mb-3">
                              <CInputGroupText
                                ><i class="mdi mdi-shield-half-full"></i
                              ></CInputGroupText>
                              <CFormSelect
                                id="role"
                                v-model="user.role"
                                :disabled="!register && afterRegister"
                              >
                                <option value="0">Пользователь</option>
                                <option value="1">Администратор</option>
                              </CFormSelect>
                            </CInputGroup>
                          </div>

                          <div class="col-md-12 col-lg-6">
                            <CFormLabel for="statuses">Статус</CFormLabel>
                            <CInputGroup class="mb-3">
                              <CInputGroupText><i class="mdi mdi-group"></i></CInputGroupText>
                              <CFormSelect id="statuses" v-model="user.status">
                                <option
                                  v-for="status in statuses"
                                  :key="status.value"
                                  :value="status.value"
                                >
                                  {{ status.title }}
                                </option>
                              </CFormSelect>
                            </CInputGroup>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-12">
                            <div class="form-check px-0">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                v-model="user.sessionLimit"
                                id="sessionLimit"
                              />
                              <label class="form-check-label" for="sessionLimit">
                                Ограничивать сессию одним активным аккаунтом
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="box">
                    <div class="box-header border-bottom border-dark">
                      <div class="box-title">Адрес</div>
                    </div>

                    <div class="box-body">
                      <div class="row">
                        <div class="col-md-6 col-lg-4">
                          <div class="form-group">
                            <label for="city">Город</label>
                            <input
                              id="city"
                              v-model="user.address.city"
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6 col-lg-4">
                          <div class="form-group">
                            <label for="street">Улица</label>
                            <input
                              id="street"
                              v-model="user.address.street"
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="col-md-3 col-lg-2">
                          <div class="form-group">
                            <label for="houseNum">Дом</label>
                            <input
                              id="houseNum"
                              v-model="user.address.houseNum"
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="col-md-3 col-lg-2">
                          <div class="form-group">
                            <label for="apartmentNum">Квартира</label>
                            <input
                              id="apartmentNum"
                              v-model="user.address.apartmentNum"
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6 col-lg-4">
                          <CFormLabel for="defaultDivisionnoautofill">Основная площадка</CFormLabel>
                          <div class="position-relative">
                            <CInputGroup>
                              <CInputGroupText
                                ><i class="mdi mdi-office-building"></i
                              ></CInputGroupText>
                              <CFormInput
                                id="divisionSearch"
                                v-model="divisionSearch"
                                placeholder="Поиск площадки..."
                                @focus="showDivisionDropdown = true"
                                autocomplete="off"
                              />
                            </CInputGroup>

                            <!-- Dropdown Menu -->
                            <div v-if="showDivisionDropdown" class="division-dropdown">
                              <div
                                v-for="div in filteredDefaultDivisions"
                                :key="div.guid"
                                class="dropdown-item py-2 px-3 cursor-pointer"
                                :class="{ active: user.defaultDivision === div.guid }"
                                @click="selectDefaultDivision(div)"
                              >
                                {{ div.name }}
                              </div>
                              <div
                                v-if="filteredDefaultDivisions.length === 0"
                                class="dropdown-item py-2 px-3"
                              >
                                Ничего не найдено
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="box">
                    <div class="box-header border-bottom border-dark">
                      <div class="box-title">Домофон</div>
                    </div>
                    <div class="box-body">
                      <div class="row">
                        <div class="col-md-6 col-lg-4">
                          <CFormLabel for="intercomUserName">Имя пользователя</CFormLabel>
                          <CInputGroup class="mb-3">
                            <CInputGroupText><i class="mdi mdi-phone"></i></CInputGroupText>
                            <CFormInput
                              id="intercomUserName"
                              v-model="user.sipUsername"
                              autocomplete="off"
                            />
                          </CInputGroup>
                        </div>
                        <div class="col-md-6 col-lg-4">
                          <CFormLabel for="intercomPassword">Пароль</CFormLabel>
                          <CInputGroup class="mb-3">
                            <CInputGroupText
                              ><i class="mdi mdi-form-textbox-password"></i
                            ></CInputGroupText>
                            <CFormInput
                              id="intercomPassword"
                              v-model="user.sipPassword"
                              :type="showIntercomPass ? 'text' : 'password'"
                              autocomplete="off"
                            />
                            <CInputGroupText @click="showIntercomPass = !showIntercomPass">
                              <i v-if="showIntercomPass" class="mdi mdi-eye-off"></i>
                              <i v-else class="mdi mdi-eye"></i>
                            </CInputGroupText>
                          </CInputGroup>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6 col-lg-4">
                          <CFormLabel for="sipServer">Сервер</CFormLabel>
                          <CInputGroup class="mb-3">
                            <CInputGroupText><i class="mdi mdi-server"></i></CInputGroupText>
                            <CFormInput
                              id="sipServer"
                              v-model="user.sipServer"
                              autocomplete="off"
                            />
                          </CInputGroup>
                        </div>
                        <div class="col-md-6 col-lg-4">
                          <CFormLabel for="rtmpServer">RTMP Сервер</CFormLabel>
                          <CInputGroup class="mb-3">
                            <CInputGroupText><i class="mdi mdi-server"></i></CInputGroupText>
                            <CFormInput id="rtmpServer" v-model="user.rtmpServer" autocomplete="off" />
                          </CInputGroup>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CTabPane>
            <CTabPane role="tabpanel" aria-labelledby="home-tab" :visible="tabPaneActiveKey === 2">
              <div class="row">
                <div v-if="register && !afterRegister" class="col-12">
                  <div class="box-body text-center mt-3">
                    <p>Сначала нужно сохранить созданного пользователя</p>
                  </div>
                </div>
                <div v-else class="col-12">
                  <div class="box">
                    <CFormInput
                      id="searchnoautofill"
                      v-model="divisionFilter"
                      placeholder="Поиск по площадкам"
                      autocomplete="off"
                    />
                  </div>

                  <div class="box">
                    <div class="box-body">
                      <div class="division-selector2">
                        <div
                          v-for="division in filteredSortedDivisions"
                          :key="division['guid']"
                          class="division-item"
                        >
                          <div class="division-title">
                            <div class="division-check-box">
                              <input
                                :id="division['guid']"
                                v-model="division.selected"
                                type="checkbox"
                                class="filled-in chk-col-blue"
                                @change="selectDivision(division)"
                              />
                              <label :for="division['guid']" class=""></label>
                            </div>
                            <div class="division-name">
                              {{ division.name }}<br />
                              <!--<small class="text-purple">{{ division.address }}</small><br>
                              <small class="text-gray">server ver.: {{ division.version || '-' }}<br></small>
                              <small v-if="division.lastActivity" class="text-gray">последняя активность: {{ division.lastActivity }}<br></small>-->
                            </div>

                            <div class="division-item__btn">
                              <CButton
                                color="secondary"
                                class="btn-cam"
                                @click.prevent="openCamSelectop(division)"
                                ><i class="mdi mdi-video"></i>
                              </CButton>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CTabPane>
          </CTabContent>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="box">
            <div class="box-body">
              <div class="row">
                <div class="col-12 d-flex justify-content-end">
                  <div class="btn btn-default btn-lg mx-3" @click="hide">Отмена</div>
                  <div class="btn btn-success btn-lg" @click="saveUser">Сохранить</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

    <CModal
      scrollable
      :visible="selectorModalShow"
      @close="
        () => {
          selectorModalShow = false
        }
      "
    >
      <CModalHeader>
        <CModalTitle>Камеры</CModalTitle>
      </CModalHeader>
      <CModalBody v-if="editeddevision">
        <h3 class="mb-3 text-white">{{ editeddevision.name }}</h3>
        <div class="row mb-3">
          <div class="col-12 small">
            <span class="text-info" @click="checkAll(true)">Выбрать все</span> /
            <span class="text-warning" @click="checkAll(false)">Отменить все</span>
          </div>
        </div>

        <div class="row">
          <div
            v-for="(cam, idxCam) of editeddevision.camList"
            :key="idxCam"
            class="col-6 mb-2 border-bottom border-dark"
          >
            <input
              :id="editeddevision.guid + '-' + cam.name + '-' + idxCam"
              v-model="cam.selected"
              type="checkbox"
              class="with-gap"
              @change="selectCam(editeddevision)"
            />
            <label
              :for="editeddevision.guid + '-' + cam.name + '-' + idxCam"
              class="filled-in chk-col-light-blue"
            >
              {{ cam.name }}
            </label>
          </div>
        </div>

        <div class="row">
          <div class="col-12 d-flex justify-content-end">
            <CButton
              color="success"
              @click="
                () => {
                  selectorModalShow = false
                }
              "
              >OK</CButton
            >
          </div>
        </div>
      </CModalBody>
    </CModal>
  </div>
</template>

<script>
import User from '@/services/user'
import {
  CTabContent,
  CButton,
  CTabPane,
  CNavLink,
  CFormSelect,
  CNavItem,
  CNav,
  CFormLabel,
  CInputGroup,
  CFormInput,
  CInputGroupText,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle
} from '@coreui/bootstrap-vue'
import { ApiSys } from '@/services/api'
import MD5 from 'md5'
import { inject } from 'vue'
import { passGen } from '@/helpers/utils'

export default {
  name: 'UserEdit',
  components: {
    CModal,
    CModalBody,
    CModalHeader,
    CModalTitle,
    CTabContent,
    CTabPane,
    CButton,
    CNavLink,
    CFormSelect,
    CNavItem,
    CNav,
    CFormLabel,
    CInputGroup,
    CFormInput,
    CInputGroupText
  },
  props: {
    division: {
      type: Object,
      required: true
    },
    username: String,
    hide: Function,
    register: Boolean
  },
  setup() {
    const { showModal, hideModal } = inject('modal')
    return { showModal, hideModal }
  },
  data() {
    return {
      user: null,
      usernameInternal: '',
      lockedPass: false,
      showPass: false,
      showIntercomPass: false,
      allDivisionsServer: [],
      allDivisionsRaw: [],
      allDivisions: [],
      editeddevision: null,
      tabPaneActiveKey: 1,
      divisionFilter: '',
      selectorModalShow: false,
      afterRegister: false,
      divisionSearch: '',
      showDivisionDropdown: false
    }
  },

  computed: {
    statuses() {
      return [
        {
          title: 'Активный',
          value: 'Active'
        },
        {
          title: 'Неактивный',
          value: 'Inactive'
        },
        {
          title: 'Удаленный',
          value: 'Deleted'
        }
      ]
    },

    filteredDivisions() {
      return this.divisionFilter.length
        ? this.allDivisions.filter((u) =>
            u.name.toLowerCase().includes(this.divisionFilter.toLowerCase())
          )
        : this.allDivisions
    },

    filteredDefaultDivisions() {
      return this.divisionSearch.length
        ? this.allDivisions.filter((u) =>
            u.name.toLowerCase().includes(this.divisionSearch.toLowerCase())
          )
        : this.allDivisions
    },

    filteredSortedDivisions() {
      const deepCloned = this.filteredDivisions

      deepCloned.sort((a, b) => {
        const smallA = String(a.name).toLowerCase()
        const smallB = String(b.name).toLowerCase()

        if (smallA < smallB) {
          return -1
        }
        if (smallA > smallB) {
          return 1
        }
        return 0
      })

      return deepCloned
    },

    defaultDivisionName() {
      const foundDD = this.allDivisions.find((d) => d.guid === this.user.defaultDivision)

      return foundDD ? foundDD.name : ''
    },
    role: {
      get() {
        return this.user.role.toString()
      },
      set(value) {
        this.user.role = parseInt(value)
      }
    }
  },

  async beforeCreate() {
    await User.checkAdmin()
  },
  async mounted() {
    this.usernameInternal = this.username
    this.updateDate()
  },
  methods: {
    async updateDate() {
      await this.get()
      // await this.getDivisions();
      await this.prepareDivisions()
      this.allDivisionsRaw = [...this.allDivisions]
    },
    async get() {
      if (this.register && !this.afterRegister) {
        this.user = {
          user: null,
          password: passGen(),
          email: null,
          divisions: [],
          address: {
            city: null,
            street: null,
            houseNum: null,
            apartmentNum: null
          },
          role: 0,
          sessionLimit: false,
          group: null
        }
      } else {
        const response = await ApiSys.getUser({ user: this.usernameInternal })
        if (response.error) {
          this.$toast.error(response.error.message)
        }
        this.user = response.data
        if (!this.user.address) {
          this.user.address = {
            city: null,
            street: null,
            houseNum: null,
            apartmentNum: null
          }
        }
      }
    },

    async saveNewUser() {
      this.user.divisionUuid = this.division.id
      this.user.name = this.user.user
      let result = await ApiSys.adminRegisterUser({
        ...this.user,
        password: MD5(this.user.password)
      })

      if (result.error) {
        this.$toast.error(result.error.message)
        return false
      }
      this.$toast.success('Пользователь успешно сохранен')

      return true
    },
    selectDefaultDivision(division) {
      this.user.defaultDivision = division.guid
      this.divisionSearch = division.name;
      this.showDivisionDropdown = false
    },
    async saveCreatedUser() {
      const userDivisions = this.allDivisions
        .filter((e) => e.selected)
        .map((e) => {
          const camList = e.camList.filter((c) => c.selected).map((c) => c.name)
          return { divisionUuid: e.guid, camList }
        })
      const savedUser = Object.assign({}, this.user)
      savedUser.divisionsWithCams = userDivisions
      savedUser.divisionsOp = 'replace'
      savedUser.status = this.statuses.indexOf(
        this.statuses.find((s) => s.value === this.user.status)
      )
      delete savedUser.divisions
      const response = await ApiSys.patchUserAdmin(this.user.user, savedUser)
      if (response.error) {
        this.$toast.error(response.error.message)
      } else {
        this.$toast.success('Пользователь успешно сохранен')
      }
    },

    async saveUser() {
      if (this.register && !this.afterRegister) {
        const res = await this.saveNewUser()
        if (res) {
          this.usernameInternal = this.user.user
          // const division = this.store.lastSelectedGroup;
          this.afterRegister = true
          this.updateDate()
        }
      } else {
        await this.saveCreatedUser()
      }
    },

    async prepareDivisions() {
      if (this.register && !this.afterRegister) {
        return
      }
      this.allDivisionsServer = await ApiSys.getAdminDivisions()
      this.allDivisions = User.prepareViewDivisions([...this.allDivisionsServer], this.user)
      this.allDivisions.forEach((division) => {
        this.selectCam(division)
      })
      if (this.user.defaultDivision) {
        this.selectDefaultDivision(this.allDivisions.find((d) => d.guid === this.user.defaultDivision))
      }
    },

    toggleShowPass() {
      this.showPass = !this.showPass
    },

    selectDivision(division) {
      let newStatus = division.selected
      division.camList.map(function (cam) {
        cam.selected = newStatus

        return cam
      })
    },

    /**
     * помечаем дивижн если хоть одна камер выбран
     *
     * @param division
     */
    selectCam(division) {
      const userSameDivision = this.allDivisions.find((d) => d.guid === division.guid)

      if (userSameDivision) {
        division.selected = userSameDivision.camList.some((e) => e.selected)
      } else {
        division.selected = false
      }
    },

    openCamSelectop(division) {
      this.editeddevision = division
      this.selectorModalShow = true
    },

    checkAll(all) {
      this.editeddevision.camList.map(function (cam) {
        cam.selected = !!all

        return cam
      })
      const userSameDivision = this.allDivisions.find((d) => d.guid === this.editeddevision.guid)
      if (userSameDivision) {
        userSameDivision.selected = userSameDivision.camList.some((e) => e.selected)
      } else {
        userSameDivision.selected = false
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
#admin-user-edit {
  padding: 10px
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100% - 50px);

  a {
        color : #ccc
      }
      a:hover {
        text-decoration: none;
      }
  a:not(.active):hover {
    color : #000;
  }
}


.division-selector2 {
  display: flex
  justify-content: space-between
  flex-wrap: wrap

  .division-item {
    width: 32%
    margin: 0 1% 1% 0
    background: #383e47
    padding: 8px

    .division-title {
      overflow: hidden
      display: flex
      margin-bottom: 10px
      position: relative

      .division-check-box {
        float: left;
        width: 30px
      }

      .division-name {
        color: #fff;
        flex-grow: 1;
        word-break: break-all
      }
      .btn-cam, .btn-select-group, .btn-star {
        // position: absolute;
        top: 0;
        right: 0;
        text-align: center;
        font-size: 24px;
        width: 34px;
        padding: 0;
        height: 32px;
        line-height: 0px;
      }

    }
  }

  .division-item__btn {
    display flex;
    flex-direction: column
  }
}


.small-screen {

  .division-selector2 {
    display: block

    .division-item {
      width: 100%
    }
  }


}

.dropdown-item {
  &:hover {
    background-color: #343a40
  }

  &.active {
    background-color: #2f3438
  }
}

.division-dropdown {
  position: absolute;
  width: 100%;
  z-index: 1000;
  max-height: 300px;
  overflow-y: auto;
  background-color: #272c33;
  border-radius: 0.375rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.47);
}


/*.division-selector {

  label.division-name {
    line-height: 18px;
    height: auto;
    word-break break-word
  }

  .sub {
    margin-left: 20px

    label {
      height: 20px
      line-height: 20px
    }
  }
}*/
</style>
