<template>
  <footer class="main-footer">
    <span class="color-grey-500">Copyright © 2023 All rights reserved.</span>
    <span class="version">ver.: 1.3.17/{{ serverVersion }}/<span v-if="mediaServerVersion">{{ mediaServerVersion }}</span></span>
    <span v-if="currentCam" class="version">server ver.: {{ currentCam.version || '-' }}</span>
  </footer>
</template>

<script>
import { useDefaultStore } from '@/store';
export default {
  name: 'Sidebar',

  props: {
    currentUser: {
      default: () => {
        return null;
      },
      type: Object
    }
  },
  setup() {
    const store = useDefaultStore();
    return {
      store
    };
  },
  computed: {
    allCams() {
      return this.store.camNames;
    },
    mediaServerVersion() {
      if (this.store.currentDivision) {
        return this.store.currentDivision.version;
      }
      return null;
    },

    serverVersion() {
      return this.store.serverVersion;
    },

    divisionGuid() {
      try {
        return this.$route.params.division || null;
      } catch (e) {
        return null;
      }
    },
    currentCam() {
      try {
        return this.allCams.find(e => e.uuid === this.divisionGuid);
      } catch (e) {
        return null;
      }
    }
  }
};
</script>

<style lang="stylus">
  .main-footer {
    border-top: 1px solid c-border;
    text-align: center;
    padding: 5px 0;
    font-size: 11px;
  }

  .version {
    display inline-block
    margin 0 10px
  }
</style>
