<template>
    <div class="h-100">
  <div class="page-header flex-row">
    <div class="d-none d-md-block title">Статус серверов</div>
    <div class="flex-shrink flex-row m-auto">
        <CInputGroup size="sm">

      <CFormInput type="text" v-model="search" autocomplete="off" placeholder="Поиск по серверам" />
      <CButton color="success" class="mr-2" @click="showOnlyFresh = !showOnlyFresh">{{ showOnlyFresh ? 'Показать все' : 'Показать только свежие' }}</CButton>
    </CInputGroup>
    </div>
  </div>
  <div class="server-status-container">
    <div class="server-grid">
        <ServerInfo :server="server" v-for="server in servers" :key="server.guid" class="server-card" />
    </div>
  </div>
</div>
</template>

<script>
import ServerInfo from '@/components/ServerInfo.vue'
import { useDefaultStore } from '@/store';
import { checkVersion } from '@/helpers/utils';
import { CButton, CFormInput, CInputGroup  } from '@coreui/bootstrap-vue';
export default {
  components: {
    ServerInfo,
    CButton,
    CFormInput,
    CInputGroup
  },
  setup() {
    const store = useDefaultStore()
    return {
      store
    }
  },
  data() {
    return {
      search: '',
      showOnlyFresh: true
    }
  },
  mounted() {
    this.store.setPageTitle('Статус серверов')
  },
  computed: {
    servers() {
        if (!this.store.allDivisions) {
            return [];
        }
        // Create a new sorted array instead of mutating the original
        const sortedServers = [...this.store.allDivisions].sort((a, b) => {
            return a.name.localeCompare(b.name);
        });
        const newServers = this.showOnlyFresh ? sortedServers.filter(server => checkVersion(server.version, '1.0.0.81')) : sortedServers;
        const search = this.search.toLowerCase();
        if (search.length > 3) {
            return newServers.filter(server => {
                return server.name.toLowerCase().includes(search) || server.address.toLowerCase().includes(search)
            })
        }
        return newServers;
      }
        }
}
</script>

<style scoped>
.server-status-container {
  padding: 2rem;
  height: 100%;
}

.page-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 2rem;
  color: #333;
}

.server-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  overflow-y: scroll;
  height: 90%;
  scrollbar-width: thin;
  scrollbar-color: #eee rgba(0, 255, 255, 0);
  scrollbar-arrow-color: rgba(0, 255, 255, 0);
}
</style>
