<template>
  <div class="archive-interval-download">
    <div class="input-group">
      <TimeInput v-model="rangeStart" class="mx-2" />
      <TimeInput v-model="rangeEnd" />
    </div>

    <ul class="interval-modal__errors" v-if="hasError">
      <li class="interval-modal__error" v-for="error in errors" :key="error">
        {{ error }}
      </li>
    </ul>
    <div class="d-flex justify-content-between mt-2">
      <CButton class="mx-2" color="secondary" @click="hideModal">Закрыть</CButton>
      <CButton color="primary" @click="nextStep">Скачать</CButton>
    </div>
  </div>
</template>

<script setup>
import TimeInput from '@/components/TimeInput.vue'
import { CButton } from '@coreui/bootstrap-vue'
import { inject, ref, defineProps, computed, watch } from 'vue'
import { useDefaultStore } from '@/store'
import moment from 'moment'
import axios from 'axios'
import DownloadsList from './DownloadsList.vue'

const ERROR = {
  START_OUT: `Левая граница меньше минимального времени`,
  END_OUT: `Правая граница больше максимального времени`,
  WRONG_ORDER: `Левая граница больше правой`,
  MAX_INTERVAL: `Максимальный отрезок для загрузки - 2 часа`
}

const { showModal, hideModal } = inject('modal')

const store = useDefaultStore();

const props = defineProps({
  range: {
    type: Object,
    default: () => {
      return { startTime: 0, endTime: 84399 }
    }
  },
  division: {
    type: Object,
    default: () => {}
  },
  cam: {
    type: String,
    default: ''
  },
  date: {
    type: Date,
    default: () => new Date()
  },
  type: {
    type: String,
    default: 'device'
  }
})

const rangeStart = ref(secondsToDateString(props.range.startTime))
const rangeEnd = ref(secondsToDateString(props.range.endTime))
const errors = ref([])

watch(props.range, () => {
  rangeStart.value = secondsToDateString(props.range.startTime)
  rangeEnd.value = secondsToDateString(props.range.endTime)
})

const hasError = computed(() => errors.value.length > 0)
const interval = computed(() => [rangeStart.value, rangeEnd.value])

function secondsToDateString(seconds) {
  const date = new Date(seconds * 1000)

  try {
    return date.toISOString().split(/[T.]/)[1]
  } catch (e) {
    return '00:00:00'
  }
}

function dateStringToSeconds(dateString) {
  const timeParts = dateString.split(/:/)
  let result = 0

  if (timeParts[0]) {
    result += Number(timeParts[0]) * 3600
  }

  if (timeParts[1]) {
    result += Number(timeParts[1]) * 60
  }

  if (timeParts[2]) {
    result += Number(timeParts[2])
  }

  return result
}

function checkValidity() {
  errors.value = []
  let isValid = true

  const [start, end] = interval.value
  const min = 0;
  const max = 84399;

  if (start < min) {
    isValid = false
    errors.value.push(ERROR.START_OUT)
  }

  if (end > max) {
    isValid = false
    errors.value.push(ERROR.END_OUT)
  }

  if (start >= end) {
    isValid = false
    errors.value.push(ERROR.WRONG_ORDER)
  }
  const user = store.currentUser;
  if (user && user.user !== 'admin') {
    if (dateStringToSeconds(end) - dateStringToSeconds(start) > 7200) {
      isValid = false
      errors.value.push(ERROR.MAX_INTERVAL)
    }

    return isValid
  }
}
async function nextStep(event) {
      event.preventDefault()
      checkValidity()

      if (!hasError.value) {
        const dateStart = dateStringToSeconds(rangeStart.value)
        const dateEnd = dateStringToSeconds(rangeEnd.value)
        const formattedDate = moment(props.date).format('YYYY_MM_D');
        const url = window.location.protocol === 'http:' ? `http://${props.division.address}` : `https://${props.division.url}`;
        const transcodeUrl = `${url}/api/transcodeToUser`;
        const concatUrl = `${url}/api/concatToCloud`;
        const options = {
          method: 'POST',
      url: props.type === 'device' ? transcodeUrl : concatUrl,
      headers: { 'Content-Type': 'application/json'},
      data: {
        uuid: props.division.uuid,
          cam: props.cam,
          date: formattedDate,
          from: dateStart,
          to: dateEnd
          }
        }

        await axios.request(options)
        showModal(DownloadsList, {}, {}, "Список скачиваний", 'lg')
      }
    }
</script>
