<template>
  <div id="FavoriteList">
    <div class="main-content-favorite" v-if="allDivisions.length">
      <template v-for="(division, index) of favGroups" :key="index">
        <div class="division-title">{{ currentDivision(division[0].division).name }}</div>
        <div class="grid-wrapper">
          <div class="cams-grid">
            <a v-for="cam of division" :key="cam.cam" class="cam-layout" href="#" @click.prevent>
              <div class="cam-container">
                <CamPlayerLivePreview
                  :source="getCamSourceUrl(cam.cam, division[0].division)"
                  class="cam"
                  :key="cam.cam"
                  :is-favorite="isInFavorite(cam.cam, division[0].division)"
                  :show-controls-forced="store.showCamName"
                  :show-timer="store.showTimer"
                  :cam-name="cam.cam"
                  @click-cam="routeToCam(element)"
                  @toggle-favorite="toggleFavorite(cam.cam, division[0].division)"
                />
              </div>
            </a>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import CamPlayerLivePreview from '@/components/cam/CamPlayerLivePreview.vue'
import * as _ from 'lodash-es'
import { useDefaultStore } from '@/store'
import { Favorites } from '@/services/favorites'
import { ApiSys } from '@/services/api'
import { useToast } from 'vue-toast-notification'

const router = useRouter()
const store = useDefaultStore()
const toast = useToast()
const allDivisions = ref([])

// Computed properties
const inFavoriteList = computed(() => store.favoriteList)
const favList = computed(() => store.favoriteList)
const gridCam = computed(() => store.gridCam)
const favGroups = computed(() => _.groupBy(favList.value, 'division'))

// Methods
const isInFavorite = (cam, divisionGuid) => {
  const res = inFavoriteList.value.find(
    (e) => e.division === currentDivision(divisionGuid).uuid && e.cam === cam
  )
  return res !== undefined
}

const toggleFavorite = async (cam, divisionGuid) => {
  try {
    if (isInFavorite(cam, divisionGuid)) {
      await Favorites.remove({
        division: divisionGuid,
        cam
      })
      toast.success('Камера удалена из избранного')
      return
    }
    await Favorites.add({
      division: divisionGuid,
      cam
    })
    toast.success('Камера добавлена в избранное')
  } catch (e) {
    console.log(e)
  }
}
const routeToCam = (cam, divisionGuid) => {
  router.push({
    name: 'cam-new',
    params: { cam, division: divisionGuid }
  })
}

const currentDivision = (guid) => {
  const foundDivision = allDivisions.value.find((d) => d.uuid === guid)
  return foundDivision || {}
}

const getDivisions = async () => {
  const result = await ApiSys.getDivisions()
  if (result.error) {
    toast.error(result.error.message)
    allDivisions.value = []
  } else {
    allDivisions.value = result.data
  }
}

const getCamSourceUrl = (cam, divisionGuid) => {
  const division = store.allDivisions.find((d) => d.guid === divisionGuid)
  if (!division) return undefined

  return window.location.protocol === 'http:'
    ? `http://${division.address}/${division.guid}/${cam}/m/index.m3u8`
    : `https://${division.url}/${division.guid}/${cam}/m/index.m3u8`
}

// Lifecycle hooks
onMounted(async () => {
  await getDivisions()
  store.setPageTitle('Избранное');
})
</script>

<style scoped>
#FavoriteList {
  width: 100%;
  overflow: auto;
  height: calc(100% - 56px);
  position: relative;
  margin-top: 5px;
}

#FavoriteList .main-content {
  max-width: 900px;
  margin: 0 auto;
  padding: 0;
  height: 100%;
  overflow: auto;
}

#FavoriteList .division-title {
  color: #fff;
  padding: 10px;
  text-align: left;
  font-weight: bold;
  margin-left: 10px;
  background: #272c33;
}

#FavoriteList.has-pagination {
  padding-bottom: 50px;
}

#FavoriteList.has-pagination.has-pagination_fullscreen {
  padding-bottom: 0;
  height: 100%;
}

#FavoriteList .cam-layout {
  position: relative;
  height: fit-content;
  background: #151515;
  display: block;
}
#FavoriteList .cams-grid {
  display: grid;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  grid-template-columns: repeat(v-bind(gridCam), minmax(0, 1fr));
  grid-auto-rows: 1fr;
  gap: 10px;
  padding: 10px;
}

#FavoriteList .cam-container {
  width: 100%;
  height: 100%;
  aspect-ratio: 16/9;
}

#FavoriteList .ghost {
  border-left: 6px solid rgb(0, 183, 255);
  box-shadow: 10px 10px 5px -1px rgba(0, 0, 0, 0.14);
  opacity: 0.7;
}

#FavoriteList .sortable-drag {
  opacity: 0;
}

.grid-wrapper {
  height: calc(100%);
}

@media screen and (max-width: 768px) {
  .cams-grid {
    grid-template-columns: 1fr !important;
  }
}
</style>
