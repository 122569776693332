<template>
  <div id="admin-mass-registration" class="scroll-block">
    <form>
      <div class="row">
        <div class="col-12">
          <div class="box">
            <div class="box-header border-bottom border-dark">
              <div class="box-title">Массовая регистрация пользователей</div>
            </div>
            <div class="box-body">
              <!-- Username Generation Method -->
              <div class="row mb-3">
                <div class="col-12">
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      v-model="usernameMethod"
                      id="pattern"
                      value="pattern"
                    />
                    <label class="form-check-label" for="pattern">По шаблону</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      v-model="usernameMethod"
                      id="list"
                      value="list"
                    />
                    <label class="form-check-label" for="list">Список</label>
                  </div>
                </div>
              </div>

              <!-- Pattern Fields -->
              <div v-if="usernameMethod === 'pattern'" class="row">
                <div class="col-md-6">
                  <CFormLabel for="pattern">Шаблон имени пользователя</CFormLabel>
                  <CInputGroup class="mb-3">
                    <CInputGroupText><i class="mdi mdi-account"></i></CInputGroupText>
                    <CFormInput id="pattern" v-model="formData.usernamePattern" placeholder="" />
                  </CInputGroup>
                </div>
                <div class="col-md-6">
                  <CFormLabel for="count">Количество пользователей</CFormLabel>
                  <CInputGroup class="mb-3">
                    <CInputGroupText><i class="mdi mdi-account-multiple"></i></CInputGroupText>
                    <CFormInput id="count" type="number" v-model.number="formData.count" />
                  </CInputGroup>
                </div>
              </div>

              <!-- Username List -->
              <div v-else class="row">
                <div class="col-12">
                  <CFormLabel for="userList">Список пользователей (по одному на строку)</CFormLabel>
                  <textarea
                    id="userList"
                    v-model="usernameList"
                    class="form-control"
                    rows="5"
                  ></textarea>
                </div>
              </div>
              <div class="row mt-3" v-if="usernameMethod === 'pattern'">
                <small>Доступные переменные: {city}, {street}, {house}, {apt}, {n}</small>
                <small>n - номер пользователя</small>
                <small
                  >Например: {city}_{street}_{house}_{apt}_{n} будет moskva_voykova_12_1_1,
                  moskva_voykova_12_2_2 и т.д.</small
                >
              </div>
              <!-- Password Fields -->
              <div class="row mt-3 mb-3">
                <div class="col-md-12">
                  <CFormLabel for="defaultPassword">Пароль по умолчанию</CFormLabel>
                  <CInputGroup>
                    <CInputGroupText><i class="mdi mdi-form-textbox-password"></i></CInputGroupText>
                    <CFormInput
                      id="defaultPassword"
                      v-model="formData.defaultPassword"
                      :type="showPassword ? 'text' : 'password'"
                    />
                    <CInputGroupText @click="showPassword = !showPassword">
                      <i v-if="showPassword" class="mdi mdi-eye-off"></i>
                      <i v-else class="mdi mdi-eye"></i>
                    </CInputGroupText>
                  </CInputGroup>
                  <div class="password-text">
                    Если не ввести пароль, он будет сгенерирован автоматически
                  </div>
                </div>
              </div>

              <!-- SIP Settings -->
              <div class="box mt-3">
                <div class="box-header border-bottom border-dark">
                  <div class="box-title">Настройки SIP</div>
                </div>
                <div class="box-body">
                  <div class="row">
                    <div class="col-md-6">
                      <CFormLabel for="sipServer">SIP Сервер</CFormLabel>
                      <CInputGroup class="mb-3">
                        <CInputGroupText><i class="mdi mdi-server"></i></CInputGroupText>
                        <CFormInput id="sipServer" v-model="formData.sipServer" />
                      </CInputGroup>
                    </div>
                    <div class="col-md-6">
                      <CFormLabel for="startingSipNumber">Начальный номер SIP</CFormLabel>
                      <CInputGroup class="mb-3">
                        <CInputGroupText><i class="mdi mdi-phone"></i></CInputGroupText>
                        <CFormInput
                          id="startingSipNumber"
                          type="number"
                          v-model.number="formData.startingSipNumber"
                        />
                      </CInputGroup>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <CFormLabel for="rtmpServer">RTMP Сервер</CFormLabel>
                      <CInputGroup class="mb-3">
                        <CInputGroupText><i class="mdi mdi-server"></i></CInputGroupText>
                        <CFormInput id="rtmpServer" v-model="formData.rtmpServer" autocomplete="off" />
                      </CInputGroup>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Address Information -->
              <div class="box mt-3">
                <div class="box-header border-bottom border-dark">
                  <div class="box-title">Адрес</div>
                </div>
                <div class="box-body">
                  <div class="row">
                    <div class="col-md-6">
                      <CFormLabel for="city">Город</CFormLabel>
                      <CInputGroup class="mb-3">
                        <CInputGroupText><i class="mdi mdi-city"></i></CInputGroupText>
                        <CFormInput id="city" v-model="formData.address.city" />
                      </CInputGroup>
                    </div>
                    <div class="col-md-6">
                      <CFormLabel for="street">Улица</CFormLabel>
                      <CInputGroup class="mb-3">
                        <CInputGroupText><i class="mdi mdi-road"></i></CInputGroupText>
                        <CFormInput id="street" v-model="formData.address.street" />
                      </CInputGroup>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <CFormLabel for="houseNum">Номер дома</CFormLabel>
                      <CInputGroup class="mb-3">
                        <CInputGroupText><i class="mdi mdi-home"></i></CInputGroupText>
                        <CFormInput id="houseNum" v-model="formData.address.houseNum" />
                      </CInputGroup>
                    </div>
                    <div class="col-md-6">
                      <CFormLabel for="startingApartmentNum">Начальный номер квартиры</CFormLabel>
                      <CInputGroup class="mb-3">
                        <CInputGroupText><i class="mdi mdi-numeric"></i></CInputGroupText>
                        <CFormInput
                          id="startingApartmentNum"
                          type="number"
                          v-model.number="formData.address.startingApartmentNum"
                        />
                      </CInputGroup>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Division Selection -->
              <div class="row mt-3">
                <div class="col-12">
                  <CFormLabel for="division">Площадка</CFormLabel>
                  <div class="position-relative">
                    <CInputGroup>
                      <CInputGroupText><i class="mdi mdi-office-building"></i></CInputGroupText>
                      <CFormInput
                        id="divisionSearch"
                        v-model="divisionSearch"
                        placeholder="Поиск площадки..."
                        @focus="showDivisionDropdown = true"
                        autocomplete="off"
                      />
                    </CInputGroup>

                    <!-- Dropdown Menu -->
                    <div v-if="showDivisionDropdown" class="division-dropdown">
                      <div
                        v-for="div in filteredDivisions"
                        :key="div.guid"
                        class="dropdown-item py-2 px-3 cursor-pointer"
                        :class="{ active: formData.divisionUUID === div.guid }"
                        @click="selectDivision(div)"
                      >
                        {{ div.name }}
                      </div>
                      <div v-if="filteredDivisions.length === 0" class="dropdown-item py-2 px-3">
                        Ничего не найдено
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Session Limit -->
              <div class="row mt-3">
                <div class="col-12">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="formData.sessionLimit"
                      id="sessionLimit"
                    />
                    <label class="form-check-label" for="sessionLimit">
                      Ограничивать сессию одним активным аккаунтом
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Submit Buttons -->
      <div class="row">
        <div class="col-12">
          <div class="box">
            <div class="box-body">
              <div class="row">
                <div class="col-12 d-flex justify-content-end">
                  <div class="btn btn-default btn-lg mx-3" @click="hide">Отмена</div>
                  <div class="btn btn-success btn-lg" @click="register">Зарегистрировать</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script setup>
import { ref, reactive, computed, onMounted, onUnmounted, watch, inject } from 'vue'
import { ApiSys } from '@/services/api'
import MD5 from 'md5'
import { CFormLabel, CInputGroup, CFormInput, CInputGroupText } from '@coreui/bootstrap-vue'
import MassRegistrationOutput from './MassRegistrationOutput.vue'
import { useToast } from 'vue-toast-notification'

const toast = useToast()

const { showModal, hideModal } = inject('modal')

const usernameMethod = ref('pattern')
const showPassword = ref(false)
const divisions = ref([])
console.log('Initial divisions:', divisions.value)
const usernameList = ref('')
const loading = ref(false)

const divisionSearch = ref('')
const showDivisionDropdown = ref(false)

const formData = reactive({
  usernamePattern: '',
  count: 1,
  defaultPassword: '',
  sipServer: 'sip.pervaya1.ru',
  startingSipNumber: 1001,
  rtmpServer: '',
  sipPassword: '',
  address: {
    city: '',
    street: '',
    houseNum: '',
    startingApartmentNum: 1
  },
  divisionUUID: null,
  camList: [],
  sessionLimit: false
})

// Filter divisions based on search
const filteredDivisions = computed(() => {
  if (!divisionSearch.value) return divisions.value || [] // Add fallback
  const search = divisionSearch.value.toLowerCase()
  return (divisions.value || []).filter(
    (
      div // Add fallback
    ) => div.name.toLowerCase().includes(search)
  )
})

// Handle division selection
function selectDivision(division) {
  formData.divisionUUID = division.guid
  formData.camList = division.camList
  divisionSearch.value = division.name
  showDivisionDropdown.value = false
}

// Close dropdown when clicking outside
function handleClickOutside(event) {
  const searchInput = document.getElementById('divisionSearch')
  if (searchInput && !searchInput.contains(event.target)) {
    showDivisionDropdown.value = false
  }
}

// Update search input when division is set programmatically
function updateDivisionSearch() {
  if (formData.divisionUUID) {
    const selectedDivision = divisions.value.find((d) => d.guid === formData.divisionUUID)
    if (selectedDivision) {
      divisionSearch.value = selectedDivision.name
    }
  }
}

async function loadDivisions() {
  const result = await ApiSys.getAdminDivisions()
  if (!result.error) {
    divisions.value = result
  }
}

async function register() {
  if (loading.value) return
  if (!formData.divisionUUID) {
    toast.error('Не выбрана площадка')
    return
  }
  if (!divisions.value.find((d) => d.name !== divisionSearch.value)) {
    toast.error('Неверное название площадки')
    return
  }
  if (formData.usernamePattern === '' && usernameList.value === '') {
    toast.error('Не задан шаблон или список пользователей')
    return
  }
  if (usernameMethod.value === 'pattern') {
    if (formData.usernamePattern.includes('{street}') && formData.address.street === '') {
      toast.error('Не заполнен адрес')
      return
    }
    if (formData.usernamePattern.includes('{house}') && formData.address.houseNum === '') {
      toast.error('Не заполнен номер дома')
      return
    }
    if (
      formData.usernamePattern.includes('{apt}') &&
      formData.address.startingApartmentNum === ''
    ) {
      toast.error('Не заполнен начальный номер квартиры')
      return
    }
  }
  loading.value = true
  try {
    const requestData = { ...formData }

    if (usernameMethod.value === 'list') {
      requestData.usernames = usernameList.value.split('\n').filter((u) => u.trim())
      delete requestData.usernamePattern
      delete requestData.count
    }

    requestData.password = MD5(requestData.defaultPassword)

    const result = await ApiSys.adminMassRegisterUsers(requestData)
    if (!result.error) {
      if (result.registeredUsers.length > 0) {
        hideModal()
        showModal(MassRegistrationOutput, {
          data: result.registeredUsers
        })
      } else {
        toast.error('Пользователи не зарегистрированы, все такие пользователи уже зарегистрированы')
      }
    } else {
      toast.error(`Ошибка регистрации: ${result.error}`)
    }
  } finally {
    loading.value = false
  }
}

// Lifecycle hooks
onMounted(() => {
  document.addEventListener('click', handleClickOutside)
  loadDivisions()
})

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside)
})

// Watch for divisions loading
watch(divisions, updateDivisionSearch)
</script>

<style lang="stylus" scoped>
#admin-mass-registration {
  padding: 10px
  overflow-y: scroll
  overflow-x: hidden
  height: calc(100% - 50px)
}

.cursor-pointer {
  cursor: pointer
}

.dropdown-item {
  &:hover {
    background-color: #343a40
  }

  &.active {
    background-color: #2f3438
  }
}

.division-dropdown {
  position: absolute;
  width: 100%;
  z-index: 1000;
  max-height: 300px;
  overflow-y: auto;
  background-color: #272c33;
  border-radius: 0.375rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.47);
}

.password-text {
  font-size: 0.875rem;
  color: #868e96;
}
</style>
