<template>
    <div class="d-flex flex-column">
        <div class="d-flex flex-row">
            <div class="flex-grow-1">
                <div class="d-flex flex-row flex-nowrap flex-grow-0 justify-content-between">
                    <span class="filename my-auto">{{ filename }}</span>
                    <span v-if="!isReady" class="flex-grow-0 d-flex flex-row flex-nowrap">
                        <span class="me-2 my-auto">{{ progress }}%</span>
                        <div class="spinner-border my-auto" role="status"></div>
                        <span class="my-auto">
                            <CFormCheck v-model="downloadWhenReady" id="downloadWhenReady" label="Скачать при готовности" />
                        </span>
                    </span>
                    <span v-else class="flex-grow-0">
                        <button class="btn btn-primary" @click="download()">Скачать</button>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { CFormCheck  } from '@coreui/bootstrap-vue';
import { inject } from 'vue';
export default {
  name: 'DownloadModal',
  components: {
    CFormCheck
  },
  props: {
    uuid: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    }
  },
  setup() {
    const { hideModal } = inject('modal');
    return {
      hideModal
    }
  },
  data() {
    return {
        filename: '',
      isReady: false,
      downloadWhenReady: true,
      es: null,
      progress: 0
    }
  },
  mounted() {
    this.es = new EventSource(`//${this.url}/api/concatToCloudStatusSSE/${this.uuid}`);
    const vm = this;
    this.es.onmessage = (event) => {
        const data = JSON.parse(event.data);
 
        if (data.Name) {
            vm.filename = data.Name
        }
        if (data.TranscodingProgress) {
            vm.progress = Math.round(data.TranscodingProgress);
        }
        if (data.Status && data.Status === "Completed") {
            vm.isReady = true;
            vm.es.close();
            if (vm.downloadWhenReady) {
                vm.download();
                vm.hideModal();
            }
        }
    }
  },
  methods: {
    download() {
        const url = `//${this.url}/downloads/${this.filename}?downloads=true`;
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.download ="";
        // link.target = "_blank";

        // It needs to be added to the DOM so it can be clicked
        document.body.appendChild(link)
        link.click()

        // To make this work on Firefox we need to wait
        // a little while before removing it.
        setTimeout(() => {
          URL.revokeObjectURL(link.href)
          link.parentNode.removeChild(link)
        }, 0);
    }
  },
  beforeUnmount() {
    this.es.close()
  }
}
</script>

<style scoped>
.spinner-border {
    max-width: 1rem;
    max-height: 1rem;
}
.filename {
    max-width: 125px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
</style>

