import axios from 'axios';

/**
 *
 * @param method
 * @param url
 * @param options
 * @param sequence
 * @returns {Promise<boolean>}
 */
async function request(method, url, options, sequence = false, params) {

  let protocol = window.location.protocol;
  let host = window.location.hostname;
  let port = window.location.port || 443;
  if (window.location.protocol == 'https') port = 443;
  let apiUrl = `${protocol}//${host}:${port}/`;
  if (sequence) {
    url = `${protocol}//${options.address}/` + url;
  } else {
    url = apiUrl + url;
  }

  let config = {
    method: method,
    url: url,
    data: options,
    params: params,
    headers: getHeaders()
  };

  try {
    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    console.log(error);
    return {error: error}
  }
}


function getHeaders() {

  /*  let token = localStorage.token;
  if (!token) {
    token = '';
  } */

  const token = localStorage.token ? localStorage.token : '';
  return {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    token: token
  };

}

export const ApiSys = {

  async Login(options) {
    return request('POST', 'api/login', options);
  },

  async getSysInfo() {
    return request('GET', 'api/sys/info', null);
  },

  async Logout() {
    return request('POST', 'api/logout', null);
  },

  async getDivisions() {
    return request('GET', 'api/v2/divisions', null);
  },

  async deleteDivision(id) {
    return request('DELETE', `api/v2/divisions/${id}`, null);
  },

  async getAllDivisions() {
    return request('GET', 'api/v2/alldivisions', null);
  },

  async getDivisionCams(id) {
    return request('GET', `api/v2/division/${id}/cams`, null);
  },

  async getDivisionWithCams() {
    return request('GET', `api/v2/divisionswithcams`, null);
  },

  async setAllDivision(options) {
    return request('POST', 'api/createdivision', options);
  },

  async getGroups() {
    return request('GET', 'api/usergroups', null);
  },

  async updateGroups(options) {
    return request('PUT', 'api/usergroups', options);
  },

  async setDivisionParent(id, divisionId) {
    if (divisionId) {
      return request('PUT', `api/v2/DivisionGroups/${id}/SetDivisionParent?divisionId=${divisionId}`);
    }

    return request('PUT', `api/v2/DivisionGroups/${id}/SetDivisionParent`);
  },

  async createGroup(options) {
    return request('POST', `api/v2/DivisionGroups`, options);
  },

  async setGroup(id, options) {
    return request('PUT', `api/v2/DivisionGroups/${id}`, options);
  },

  async deleteGroup(id) {
    return request('DELETE', `api/v2/DivisionGroups/${id}`, null);
  },

  async getGroup(id) {
    return request('GET', `api/v2/DivisionGroups/${id}`, null);
  },

  async getAllGroups() {
    return request('GET', `api/v2/DivisionGroups`, null);
  },

  async setGroups(options) {
    return request('POST', 'api/usergroups', options);
  },

  async CheckUser() {
    return request('GET', 'api/checkuser', null);
  },

  async getCurrentUser() {
    return request('GET', 'api/v2/users/current');
  },

  async getCurrentUserInfo() {
    return request('GET', 'api/v2/users/info');
  },

  async getGroupDivisions(id) {
    return request('GET', `api/v2/users/divisions/${id}`, null);
  },

  async getAllDivisionGroups() {
    return request('GET', `api/v2/users/divisions`, null);
  },

  async getUsers() {
    return request('GET', 'api/v2/users', null);
  },

  async getUser(options) {
    return request('POST', 'api/v2/users/byName', options);
  },

  async getUsersByDivision(options) {
    return request('POST', 'api/v2/users/byDivision', options);
  },

  async updateUser(options) {
    return request('PUT', 'api/v2/users', options);
  },

  async changePassUser(options) {
    return request('POST', 'api/v2/users/changepass', options);
  },

  async registrationUser(options) {
    return request('POST', 'api/v2/users/registration', options);
  },

  async getDateSequence(options) {
    return request('POST', 'api/getdatesequence', options, true);
  },

  async getArchiveForMonth(options) {
    return request('POST', 'api/GetArchiveForMonth', options, true);
  },

  async getDownloadLink(options) {
    return request('POST', 'api/downloadvideo', options, true);
  },

  async cancelDownloadLink(options) {
    return request('POST', 'api/canceldownloadvideo', options, true);
  },

  async changeCameraOrder(options) {
    return request('POST', 'api/v2/users/changecameraorder', options);
  },

  async getFavorites() {
    return request('GET', 'api/v2/users/favorites');
  },

  async addToFavorite(options) {
    return request('POST', 'api/v2/users/favorites', options);
  },

  async deleteFromFavorite(options) {
    return request('DELETE', 'api/v2/users/favorites', options);
  },

  async getProgress(options, params) {
    const response = await request('GET', 'api/getprogress', options, true, params);
    if (response.error) {
      throw new Error(response.error);
    }
    if (!response) return [];

    if (Array.isArray(response)) {
      return response.map(file => ({
        ...file,
        percent: Math.floor(file.percent)
      }));
    }

    return Object.entries(response).map(([filename, percent]) => ({
      filename,
      percent: Math.min(percent, 99), // dont show 100, until we have paths
      ready: false, // ready, when downloadvideo is finished
      fullPath: '' // will be set from another request
    }));
  },

  async getAdminGroupTree() {
    return request('GET', 'api/v3/admin/group/tree');
  },

  async getAdminGroup(id) {
    return request('GET', `api/v3/admin/group/${id}`);
  },

  async deleteAdminGroup(id) {
    return request('DELETE', `api/v3/admin/group/${id}`);
  },

  async getUsersInDivision(id) {
    return request('GET', `api/v3/admin/user/division/${id}`);
  },

  async openIntercomDoor(divisionId) {
    return request('POST', `api/v3/user/division/${divisionId}/open-intercom-door`);
  },

  async getAdminDivisions() {
    return request('GET', `api/v3/admin/division/`);
  },
  async getAdminDivision(uuid) {
    return request('GET', `api/v3/admin/division/${uuid}`);
  },
  async getAdminUsers() {
    return request('GET', `api/v3/admin/user`);
  },

  async addUserGroupAdmin(user, group) {
    return request('PUT', `api/v3/admin/user/addgroup/${user}/${group}`);
  },
  async addUserDivisionAdmin(user, division) {
    return request('PUT', `api/v3/admin/user/adddivision/${user}/${division}`);
  },

  async adminRegisterUser(user) {
    return request('POST', 'api/v3/admin/user/register', user);
  }
  ,
  async setUserDivisionAdmin(user, division) {
    return request('POST', `api/v3/admin/user/setdivision/${user}/${division}`);
  },
  async patchUserAdmin(user, options) {
    return request('PATCH', `api/v3/admin/user/${user}`, options);
  },
  async patchUsersAdmin(options) {
    return request('PATCH', 'api/v3/admin/user/many', options);
  },
  async getUserDivisions() {
    return request('GET', 'api/v3/user/division');
  },
  async adminMassRegisterUsers(options) {
    return request('POST', 'api/v3/admin/user/mass-register', options);
  }
};