import * as _ from 'lodash-es';
import { useDefaultStore } from '@/store';
const DEFAULT_SETTINGS = {
  interface: {
    gridCam: 3,
    showCamName: false,
    showTimer: true,
    hideSidebar: false,
    camOrder2: {},
  }
};

export function init() {
  const store = useDefaultStore();
  const user = store.currentUser;
  try {
    if (!user) return;
    console.log(user.user);
    const oldSettings = localStorage.getItem('settings');
    let currentSettings = localStorage.getItem(`settings_${user.user}`);
    if (!currentSettings && oldSettings) {
      currentSettings = oldSettings;
      localStorage.removeItem(`settings`);
    }
    if (!currentSettings) {
      localStorage.setItem(`settings_${user.user}`, JSON.stringify(DEFAULT_SETTINGS));
      return;
    }

    const parsedSettings = JSON.parse(currentSettings);
    const mergedSettings = _.merge({}, DEFAULT_SETTINGS, parsedSettings);
    localStorage.setItem(`settings_${user.user}`, JSON.stringify(mergedSettings));
  } catch (error) {
    console.error('Failed to initialize settings:', error);
    localStorage.setItem(`settings_${user.user}`, JSON.stringify(DEFAULT_SETTINGS));
  }
}

/**
 * Возвращает заданную настройку по пути
 */
export function getSettingValue(path) {
  try {
    const store = useDefaultStore();
    const user = store.currentUser;
    const settings = localStorage.getItem(`settings_${user.user}`);
    if (!settings) {
      init();
      return _.get(DEFAULT_SETTINGS, path, null);
    }
    
    const parsedSettings = JSON.parse(settings);
    return _.get(parsedSettings, path, null);
  } catch (error) {
    console.error('Failed to get setting value:', error);
    return _.get(DEFAULT_SETTINGS, path, null);
  }
}

/**
 * Задает значение настройке
 */
export function setSettingValue(path, value) {
  try {
    const store = useDefaultStore();
    const user = store.currentUser;
    let settings = localStorage.getItem(`settings_${user.user}`);
    if (!settings) {
      init();
      settings = JSON.stringify(DEFAULT_SETTINGS);
    }

    const parsedSettings = JSON.parse(settings);
    const updatedSettings = _.set(parsedSettings, path, value);
    
    localStorage.setItem(`settings_${user.user}`, JSON.stringify(updatedSettings));
    return true;
  } catch (error) {
    console.error('Failed to set setting value:', error);
    return false;
  }
}
