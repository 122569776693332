<template>
  <ul v-if="nodes">
    <TreeItem
      v-for="node of nodes"
      :key="node.key"
      :item="node"
      ref="rootNodeRef"
      :lazy="lazy"
      :components="$slots"
      :refetch="refetch"
      :parent="null"
    />
  </ul>
</template>

<script setup>
import TreeItem from './TreeItem.vue'
import { ref, provide, onMounted, computed } from 'vue'
import { generateKeys } from './utils'

const props = defineProps({
  root: null,
  expandedKeys: {
    type: Object,
    default: () => ({})
  },
  lazy: {
    type: Object,
    default: () => ({})
  },
  refetch: {
    type: Object,
    default: () => ({})
  },
  filterValue: {
    type: String,
    default: ''
  },
  filterMode: {
    type: String,
    default: 'lenient'
  }
})

const value = ref(generateKeys(props.root))
const d_expandedKeys = ref(props.expandedKeys || {})
const rootNodeRef = ref(null)

// Add nodes computed property
const nodes = computed(() => value.value || [])

// Provide expanded keys to child components
provide('treeKeys', d_expandedKeys.value)

// Auto-open root node
onMounted(async () => {
  if (nodes.value?.[0]) {
    d_expandedKeys.value[nodes.value[0].key] = true
    if (rootNodeRef.value?.[0]) {
      await rootNodeRef.value[0].open()
    }
  }
})
</script>

<style scoped>
ul {
  padding: 0;
}
</style>