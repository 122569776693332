<template>
    <div class="archive-interval-download">
    <div class="input-group">
        <TimeInput v-model="rangeStart" class="mx-2" />
        <TimeInput v-model="rangeEnd" />
      </div>

      <ul class="interval-modal__errors" v-if="hasError">
        <li class="interval-modal__error" v-for="error in errors" :key="error">
          {{ error }}
        </li>
      </ul>
      <div class="d-flex justify-content-between mt-2">
      <CButton class="mx-2" color="secondary" @click="hide">Закрыть</CButton>
      <CButton color="primary" @click="emitInterval">Скачать</CButton>
    </div>
    </div>
</template>

<script>
import TimeInput from '@/components/TimeInput.vue'
import { CButton } from '@coreui/bootstrap-vue'
import moment from 'moment'
const ERROR = {
  START_OUT: `Левая граница меньше минимального времени`,
  END_OUT: `Правая граница больше максимального времени`,
  WRONG_ORDER: `Левая граница больше правой`,
  MAX_INTERVAL: `Максимальный отрезок для загрузки - 2 часа`
}

export default {
    name: 'ArchiveIntervalDownload',
    components: {
        TimeInput, CButton
    },
    props: {
    maxInterval: {
      type: Array,
      default: () => [null, null]
    },
    range: {
      type: Object,
      default: () => {
        return { startTime: 0, endTime: 84399 }
      }
    },
    url: {
      type: String,
      default: ''
    },
    uuid: {
      type: String,
      default: ''
    },
    cam: {
      type: String,
      default: ''
    },
    date: {
      type: Date,
      default: ''
    },
    hide: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      rangeStart: null,
      rangeEnd: null,
      errors: []
    }
  },
  computed: {
    hasError() {
      return Boolean(this.errors.length)
    },

    interval() {
      return [this.rangeStart, this.rangeEnd]
    },

    maxIntervalString() {
      return [
        this.secondsToDateString(this.maxInterval[0]),
        this.secondsToDateString(this.maxInterval[1])
      ]
    }
  },

  watch: {
    interval() {
      this.checkValidity()
    },
    range() {
      this.rangeStart = this.secondsToDateString(this.range.startTime)
      this.rangeEnd = this.secondsToDateString(this.range.endTime)
    }
  },

  async mounted() {
    this.rangeStart = this.secondsToDateString(this.range.startTime)
    this.rangeEnd = this.secondsToDateString(this.range.endTime)
  },

  methods: {
    secondsToDateString(seconds) {
      const date = new Date(seconds * 1000)

      try {
        return date.toISOString().split(/[T.]/)[1]
      } catch (e) {
        return '00:00:00'
      }
    },

    dateStringToSeconds(dateString) {
      const timeParts = dateString.split(/:/)
      let result = 0

      if (timeParts[0]) {
        result += Number(timeParts[0]) * 3600
      }

      if (timeParts[1]) {
        result += Number(timeParts[1]) * 60
      }

      if (timeParts[2]) {
        result += Number(timeParts[2])
      }

      return result
    },

    checkValidity() {
      this.errors = []
      let isValid = true

      const [start, end] = this.interval
      const [min, max] = this.maxIntervalString

      if (start < min) {
        isValid = false
        this.errors.push(ERROR.START_OUT)
      }

      if (end > max) {
        isValid = false
        this.errors.push(ERROR.END_OUT)
      }

      if (start >= end) {
        isValid = false
        this.errors.push(ERROR.WRONG_ORDER)
      }

      if (this.user && this.user.user !== 'admin') {
        if (this.dateStringToSeconds(end) - this.dateStringToSeconds(start) > 7200) {
          isValid = false
          this.errors.push(ERROR.MAX_INTERVAL)
        }
      }
      return isValid
    },

    emitInterval(event) {
      event.preventDefault()
      this.checkValidity()

      if (!this.hasError) {
        const dateStart = this.dateStringToSeconds(this.rangeStart)
        const dateEnd = this.dateStringToSeconds(this.rangeEnd);
        const formattedDate = moment(this.date).format('YYYY_MM_D');
        const url = `//${this.url}/concat/${this.uuid}/${this.cam}?date=${formattedDate}&from=${dateStart}&to=${dateEnd}&downloads=true`;
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.download ="";

        // It needs to be added to the DOM so it can be clicked
        document.body.appendChild(link)
        link.click()

        // To make this work on Firefox we need to wait
        // a little while before removing it.
        setTimeout(() => {
          URL.revokeObjectURL(link.href)
          link.parentNode.removeChild(link)
        }, 0)
        //this.$emit('ok', [dateStart, dateEnd])
        this.emitClose()
      }
    },
    emitClose() {
      this.$emit('close')
    }
  }
}
</script>
<style>
.interval-modal__errors {
  margin-top: 1em;
  margin-bottom: 0;
  color: #ea4646;
}
</style>
