<template>
  <div class="page-header flex-row" v-if="!smallScreen">
    <div class="d-none d-md-block title">{{ pageTitle }}</div>

    <div class="flex-shrink flex-row m-auto">
      <div
        v-if="
          route === 'cam' ||
          route === 'cam-archive' ||
          route === 'cam-archive-all-cams' ||
          route === 'cam-list' ||
          route === 'cam-listNew' ||
          route === 'cam-new'
        "
        class="cam-menu"
      >
        <CButton v-if="!hideTimerButton" color="success" class="mr-2" @click="toggleShowTimer">
          <span v-if="!showTimer" class="mdi mdi-timer"></span>
          <span v-if="showTimer" class="mdi mdi-timer-off"></span>
        </CButton>

        <div
          v-if="route !== 'cam-list' && route !== 'cam-listNew'"
          class="show-name right-float"
          @click="back"
        >
          <CButton color="danger">
            <i class="mdi mdi-arrow-left-bold"></i> <span class="align-text-bottom"> Назад</span>
          </CButton>
        </div>
      </div>

      <div
        v-if="
          route === 'cam-list' ||
          route === 'cam-archive-all-cams' ||
          route === 'cam-favorites' ||
          route === 'cam-listNew'
        "
        class="flex-row ms-2"
      >
        <button v-if="route === 'cam-list' && isAdmin" class="btn-all-cams" @click="toAllCams">
          архив по всем камерам
        </button>

        <button class="btn-fullscreen" @click="onFullScreen" v-if="!fullScreen">
          <span class="mdi mdi-fullscreen"></span>
        </button>

        <div v-if="gridCam" class="d-none d-md-block grid-cam">
          <CDropdown
            color="secondary"
            id="dropdown-1"
            v-model="gridCam"
            class="m-md-2"
            style="margin: 0 !important"
          >
            <CDropdownToggle color="secondary">
              <span class="mdi mdi-grid mr-2"></span>
              <span class="mr-2">{{ gridCam + 'x' + gridCam }}</span>
            </CDropdownToggle>
            <CDropdownMenu>
              <CDropdownItem href="#" @click="gridCam = 3">
                <span class="mdi mdi-grid-large mdi-18px mr-2"></span>3 x 3
              </CDropdownItem>
              <CDropdownItem href="#" @click="gridCam = 4">
                <span class="mdi mdi-grid-large mdi-18px mr-2"></span>4 x 4
              </CDropdownItem>
              <CDropdownItem href="#" @click="gridCam = 5">
                <span class="mdi mdi-grid-large mdi-18px mr-2"></span>5 x 5
              </CDropdownItem>
              <CDropdownItem href="#" @click="gridCam = 6">
                <span class="mdi mdi-grid-large mdi-18px mr-2"></span>6 x 6
              </CDropdownItem>
            </CDropdownMenu>
          </CDropdown>
        </div>

        <div class="show-name mr-2" @click="toggleShowCamName">
          <CTooltip
            :content="showCamName ? 'Показать названия камер' : 'Убрать названия камер'"
            placement="left"
          >
            <template #toggler="{ on }">
              <CButton color="secondary" v-on="on">
                <span v-if="!showCamName" class="mdi mdi-cctv"></span>
                <span v-if="showCamName" class="mdi mdi-cctv-off"></span>
              </CButton>
            </template>
          </CTooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { useDefaultStore } from '@/store'
import {
  CButton,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CTooltip
} from '@coreui/bootstrap-vue'
export default {
  name: 'PageHeader',
  components: { CButton, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem, CTooltip },
  data: () => ({
    route: null
  }),
  props: ['hideTimerButton'],
  setup() {
    const store = useDefaultStore()
    return {
      store
    }
  },
  computed: {
    isAdmin() {
      return this.store.isAdmin
    },
    pageTitle() {
      return this.store.pageTitle
    },
    smallScreen() {
      return this.store.smallScreen
    },
    gridCam: {
      get() {
        return this.store.gridCam
      },
      set(v) {
        this.store.setGridCam(v)
      }
    },
    showTimer: {
      get() {
        return this.store.showTimer
      },
      set(v) {
        this.store.setShowTimer(v)
      }
    },
    showCamName: {
      get() {
        return this.store.showCamName
      },
      set(v) {
        this.store.setShowCamName(v)
      }
    },
    fullScreen() {
      return this.store.fullScreen
    }
  },
  watch: {
    $route(to) {
      this.route = to.name
      // this.division = this.$route.params.division;
    }
  },
  created() {
    this.route = this.$route.name
    this.division = this.$route.params.division
  },
  methods: {
    toggleShowCamName() {
      this.store.toggleShowCamName()
    },
    toggleShowTimer() {
      this.showTimer = !this.showTimer
    },
    back() {
      this.$router.go(-1)
    },
    toAllCams() {
      this.$router.push({
        name: 'cam-archive-all-cams',
        params: {
          division: this.$route.params.division,
          all: true
        }
      })
    },
    onFullScreen() {
      this.store.setFullScreen(true)
    }
  }
}
</script>

<style lang="stylus">
.btn-fullscreen {
  background: none;
  outline: none;
  padding: 0;
  border: none;
  font-size: 55px;
  color: #666;
  line-height: 1px;
  &:focus {
    outline: none;
  }
}

.btn-all-cams {
  background: none;
  outline: none;
  padding: 0;
  border: none;
  color: #666;
  font-size: 21px;

  &:hover {
    color #fff
  }
}

.bx-fullscreen {
  display: block;
}
</style>
