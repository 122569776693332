<template>
  <div class="cam-view">
    <div class="cam-view-wrap">
      <CamPlayerLive
        :show-timer="store.showTimer"
        :source="videoSrc"
        ref="video"
        :cam-name="cam"
        @screenshot="screenshot"
        @swipe="onCamMove"
        @goToArchive="goToArchive"
      >
        <FileFrameName v-if="showfilename" @close="closefile" @save="savefile" />
        <div class="switch-buttons">

          <button class="prev-cam" @click="onCamMove('left')">
            <span class="mdi mdi-chevron-left"></span>
          </button>
          <button class="next-cam" @click="onCamMove('right')">
            <span class="mdi mdi-chevron-right"></span>
          </button>
        </div>
      </CamPlayerLive>
    </div>
    <div class="cam-view-footer row" ref="footer">
      <div class="col-12">
        <div class="box">
          <div class="box-header clearfix">
            <div class="add-new float-end">
              <div class="btn btn-info" @click="goToArchive()">Архив</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CamPlayerLive from '@/components/cam/CamPlayerLive.vue'
import { inject } from 'vue'
import { useDefaultStore } from '@/store'
import FileFrameName from '@/components/FileFrameName.vue'
import { checkVersion } from '@/helpers/utils'
export default {
  name: 'CamViewNew',
  components: {
    CamPlayerLive,
    FileFrameName
  },
  data() {
    return {
      showfilename: false,
      screenData: null,
      cam: this.$route.params.cam,
    }
  },
  setup() {
    const store = useDefaultStore()
    const modal = inject('modal')
    return {
      store,
      modal
    }
  },
  computed: {
    division() {
      return this.$route.params.division
        ? this.store.allDivisions.find((e) => e.guid === this.$route.params.division)
        : null
    },
    footerHeight() {
      return this.$refs.footer ? this.$refs.footer.offsetHeight : 90;
    },
    videoSrc() {
      const address =
        window.location.protocol == 'http:' ? this.division.address : this.division.url
      return `//${address}/${this.division.guid}/${this.cam}/f/index.m3u8`
    },
    aspectRatio() {
      return this.$refs.video.aspectRatio;
    }
  },
  created() {
    window.ass = this;
    this.store.setCurrentDivision(this.division)
    this.store.setPageTitle(`Камера: ${this.division?.name} / ${this.cam}`)
  },
  methods: {
    goToArchive() {
      const version = this.division.version;
      const check = checkVersion(version, "1.0.0.72");
      if ( check ) {
        this.$router.push({ name: 'cam-archive-new', params: { cam: this.cam, division: this.division.guid } })
      } else {
        this.$router.push({ name: 'cam-archive', params: { cam: this.cam, division: this.division.guid } })
      }
    },
    onCamMove(direction) {
      console.log(direction);
      if (direction === 'left') {
        let index = this.division.camList.indexOf(this.cam) - 1
        if (index < 0) index = this.division.camList.length - 1
        this.cam = this.division.camList[index]
      }
      if (direction === 'right') {
        let index = this.division.camList.indexOf(this.cam) + 1
        if (index >= this.division.camList.length) index = 0
        this.cam = this.division.camList[index]
      }
      window.history.replaceState({}, '', `/${this.$route.name}/${this.cam}/${this.division.guid}`)
      this.store.setPageTitle(`Камера: ${this.division?.name} / ${this.cam}`)
    },
    savefile(fileName) {
      const frameUrl = this.screenData
      const link = document.createElement('a')
      link.setAttribute('href', frameUrl)
      link.setAttribute('download', `${fileName}.jpg`)
      link.click()
      this.showfilename = false
    },
    closefile() {
      this.showfilename = false
    },
    screenshot(screenData) {
      this.screenData = screenData
      this.showfilename = true
    }
  }
}
</script>
<style scoped>
.cam-view {
  height: calc(100% - 30px);
  display: flex;
  flex-direction: column;
}
.cam-view-wrap {
  display: flex;
  margin-bottom: 1px;
  background-color: #000;
  justify-content: center;
  aspect-ratio: v-bind(aspectRatio);
  max-height: calc(100% - 85px);
}

.switch-buttons {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.prev-cam,
.next-cam {
  font-size: 24px;
}
</style>
