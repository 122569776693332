<template>
  <div class="player-wrapper">
    <div data-vjs-player ref="videoParent">
      <video class="video-js vjs-default-skin" ref="video"></video>
      <div
        v-if="playerMounted"
        @click.self="togglePlay"
        @dblclick="toggleFullScreen"
        @mouseover="mouseEnter"
        @mouseleave="mouseLeave"
        @touchstart="touchStart"
        @touchend="touchEnd"
        class="custom-ui"
      >
        <slot></slot>
        <div v-if="showControls || true" class="controls">
          <button
            @click.stop="togglePlay"
            class="mdi"
            :class="{ 'mdi-pause': !paused, 'mdi-play': paused }"
          ></button>
          <span class="cam-name">{{ camName }}</span>
          <button class="live">
            <span class="livetext">LIVE</span>
          </button>
          <button @click.stop="screenshot" class="screenshot">
            <span class="mdi mdi-camera" title="Скриншот"></span>
          </button>
          <div class="zoom" v-if="!videoZoom.isTouchscreen">
            <div class="zoom-selected" @click.stop="toggleZoom">
              <span class="mdi mdi-magnify-expand" title="Зум"></span>
            </div>
            <div class="zoom-slider">
              <Transition name="slide-fade">
                <vue-slider
                  v-show="zoomShow"
                  v-model="videoZoom.scale"
                  :interval="0.01"
                  :min="1"
                  :max="3"
                  style="width: 80px; margin-left: 10px"
                ></vue-slider>
              </Transition>
            </div>
          </div>
          <div class="controls-right">
            <div class="quality-levels">
              <button   
                title="Качество"
                @click.stop="showQualityLevels = !showQualityLevels"
                class="mdi mdi-cog-box"

              ></button>
              <Transition name="slideUp-fade">
              <div v-if="showQualityLevels" class="quality-menu">
                <ul ref="qualityMenu">
                  <li
                    @click.stop="setQuality(quality.value)"
                    :class="{ active: quality.active }"
                    v-for="quality in qualityLevels"
                    :key="quality.label"
                  >
                    {{ quality.label }}
                  </li>
                </ul>
              </div>
            </Transition>
            </div>
            <button title="Архив" @click.stop="goToArchive" class="mdi mdi-archive-clock-outline"></button>
            <button title="Полный экран" @click.stop="toggleFullScreen" class="mdi mdi-fullscreen fullscreen"></button>
          </div>



        </div>
        <span v-if="showTimer" class="time">{{ time }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { shallowRef, ref } from 'vue'
import videojs from 'video.js'
import { eventsMap, events } from './events'
import moment from 'moment'
import { useDefaultStore } from '@/store'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import { lockOrientationUniversal } from '@/helpers/utils'
import { VideoZoom } from './videoZoom'
export default {
  name: 'CamPlayerLive',
  components: {
    VueSlider
  },
  setup() {
    const player = shallowRef({})
    const store = useDefaultStore()
    const videoZoom = ref(new VideoZoom())
    return { player, store, videoZoom }
  },
  props: {
    source: {
      type: String,
      required: true
    },
    camName: {
      type: String,
      required: true
    },
    showTimer: {
      type: Boolean,
      default: true
    }
  },
  emits: ['screenshot', 'touchStart', 'touchEnd'],
  data() {
    return {
      timestamp: null,
      playerMounted: false,
      paused: false,
      hoverTimeout: null,
      dotColor: '#FF0000',
      showControls: false,
      totalPlayedTime: 0,
      timeSinceLastSegment: 0,
      video: null,
      videoWidth: 0,
      videoHeight: 0,
      mouseDown: false,
      zoomShow: false,
      showQualityLevels: false,
      qualityLevels: [],
      selectedQuality: 720
    }
  },

  watch: {
    source: function (to) {
      this.player.value.src({ src: to, type: 'application/x-mpegURL' })
      this.videoZoom.resetScale()
    },
    video: {
      handler(to) {
        this.videoWidth = to.videoWidth
        this.videoHeight = to.videoHeight
      },
      deep: true
    }
  },
  computed: {
    time() {
      if (this.timestamp) {
        return moment(this.timestamp)
          .add(this.timeSinceLastSegment, 'seconds')
          .format('DD.MM.YYYY, HH:mm:ss')
      } else {
        return ''
      }
    },
    aspectRatio() {
      if (!this.videoWidth || !this.videoHeight) {
        return 16 / 9
      }
      return this.videoWidth / this.videoHeight
    },
  },
  mounted() {
    const video = this.$refs.video
    const options = {
      autoplay: true,
      controls: false,
      muted: true,
      preload: 'metadata',
      controlBar: {
        pictureInPictureToggle: false,
        bigPlayButton: false
      },
      html5: {
        vhs: {
          overrideNative: true,
          handlePartialData: true
        }
      },
      fluid: true,
      sources: [
        {
          src: this.source,
          type: 'application/x-mpegURL'
        }
      ]
    }
    this.player.value = videojs(video, options, this.playerReady)
  },
  unmounted() {
    events.forEach((eventName) => {
        const eventFunc = eventsMap[eventName]
        if (eventFunc in this) {
          this.player.value.off(eventName, this[eventFunc])
        }
      });
    this.videoObserver && this.videoObserver.disconnect()
    this.player.value.dispose()
    this.videoZoom.dispose()
  },
  methods: {
    playerReady() {
      events.forEach((eventName) => {
        const eventFunc = eventsMap[eventName]
        if (eventFunc in this) {
          this.player.value.on(eventName, this[eventFunc])
        }
      })
      this.handleTimeUpdate()
      this.video = this.player.value.el().querySelector('video')
      this.playerMounted = true
      this.videoZoom.init(this.$refs.videoParent, this.video)
      this.videoZoom.addEventHandler('swipe', this.handleSwipe);
    },
    updateQualityLevels() {
      const qualityList = this.player.value.qualityLevels()
      this.qualityLevels = [];
      console.log(qualityList);
      if (qualityList.length === 1) {
        this.qualityLevels.push({
          label: 'auto',
          enabled: true,
          active: true
        })
        return
      }
      for (let i = 0; i < qualityList.length; ++i) {
        const { width, height } = qualityList[i]
        const pixels = width > height ? height : width
        if (pixels > 1080) {
          this.qualityLevels.push({
            label: 'Original',
            value: pixels,
            active: false
          })
          continue
        }
        this.qualityLevels.push({
          label: pixels + 'p',
          value: pixels,
          active: pixels === this.selectedQuality
        })
      }
    },
    onLoadedMetadata() {
      this.updateQualityLevels();

    },
    setQuality(quality, changeQuality=true) {
      const qualityList = this.player.value.qualityLevels();
      for (let i = 0; i < qualityList.length; ++i) {
        const { width, height } = qualityList[i]
        const pixels = width > height ? height : width
        this.qualityLevels[i].active = pixels === quality;
        if (changeQuality) qualityList[i].enabled = pixels === quality || quality === 'auto'
      }
      this.selectedQuality = quality;
      this.showQualityLevels = false;
    },
    goToArchive() {
      this.$emit('goToArchive');
    },
    keepInInterval(min, max, value) {
      return Math.min(Math.max(value, min), max)

    },
    onResize() {
      this.videoHeight = this.video.videoHeight
      this.videoWidth = this.video.videoWidth
    },
    toggleFullScreen() {
      if (this.player.value.isFullscreen()) {
        this.player.value.exitFullscreen()
      } else {
        this.player.value.requestFullscreen()
      }
    },
    screenshot() {
      const video = this.player.value.el().querySelector('video')
      const canvas = document.createElement('canvas')
      canvas.style.position = 'absolute'
      canvas.width = video.videoWidth
      canvas.height = video.videoHeight

      const ctx_draw = canvas.getContext('2d')
      ctx_draw.drawImage(video, 0, 0)
      this.$emit('screenshot', canvas.toDataURL())
    },
    mouseEnter() {
      clearTimeout(this.hoverTimeout)
      this.showControls = true
    },
    onFullscreenChange() {
      if (this.player.value.isFullscreen() && this.store.smallScreen) {
        lockOrientationUniversal('landscape')
      }
    },
    mouseLeave() {
      this.hoverTimeout = setTimeout(() => {
        this.showControls = false
      }, 1500)
    },
    handleTimeUpdate() {
      const tracks = this.player.value.textTracks()
      const vm = this
      let segmentMetadataTrack
      for (let i = 0; i < tracks.length; i++) {
        if (tracks[i].label === 'segment-metadata') {
          segmentMetadataTrack = tracks[i]
        }
      }
      if (segmentMetadataTrack) {

        segmentMetadataTrack.on('cuechange', function () {
          let activeCue = segmentMetadataTrack.activeCues[0]
          if (activeCue) {
            if (activeCue.value.resolution) vm.setQuality(activeCue.value.resolution.height, false)
            const extractedDate = activeCue.value.uri
              .split('/')
              .pop()
              .split('-')
              .pop()
              .split('.')[0]
            let date = moment(extractedDate, 'YYYYMMDDHHmmss')

            if (extractedDate.length < 14) {
              date = moment()
            }
            vm.totalPlayedTime = vm.player.value.currentTime()
            vm.timeSinceLastSegment = 0
            vm.timestamp = date
          }
        })
      }
    },
    onError() {
      setTimeout(() => {
          this.player.value.reset()
          this.player.value.src({ src: this.source, type: 'application/x-mpegURL' })
          this.player.value.play().catch(err => {
            console.error('Retry Playback Error:', err)
          })
          this.error = false
        }, 3000);
    },
    onTimeUpdate() {
      this.timeSinceLastSegment = Math.floor(this.player.value.currentTime() - this.totalPlayedTime)
    },
    togglePlay() {
      if (this.paused) {
        this.player.value.play()
      } else {
        this.player.value.pause()
      }
    },
    touchStart(e) {
      this.$emit('touchStart', e)
    },
    touchEnd(e) {
      this.$emit('touchEnd', e)
    },
    onPlay() {
      this.paused = false
      this.dotColor = '#FF0000'
    },
    onPause() {
      this.paused = true
      this.dotColor = '#EEEEEE'
    },
    onWaiting() {
      this.dotColor = '#EEEEEE'
    },
    onSeeking() {
      this.dotColor = '#EEEEEE'
    },
    onPlaying() {
      this.dotColor = '#FF0000'
    },
    toggleZoom() {
      this.zoomShow = !this.zoomShow
    },
    handleSwipe(e) {
      this.$emit('swipe', e)
    }
  }
}
</script>
<style scoped>
.player-wrapper {
  /* width: 100%; */
  height: 100%;
  aspect-ratio: v-bind(aspectRatio);
}
video {
  object-fit: cover;
}
.custom-ui {
  position: absolute;
  top: 0;
  width: 100%;
  display: block;
  height: 100%;
  user-select: none;
  z-index: 2;
}
.controls {
  position: absolute;
  bottom: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  width: 100%;
  display: flex;
}

.controls-right {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
}

.cam-name {
  color: white;
  margin: auto 6px auto 0;
}
.live {
  padding: 0;
  display: flex;
}

.live::before {
  content: '';
  display: block;
  width: 6px;
  transition: background-color 0.3s ease;
  height: 6px;
  border-radius: 50%;
  background-color: v-bind(dotColor);
  margin: auto;
}
.livetext {
  color: white;
  margin: auto;
  margin-left: 6px;
}
.mdi {
  font-size: 24px;
  color: white;
}
.time {
  position: absolute;
  top: 3%;
  right: 3%;
  color: white;
  font-size: 12px;
  text-shadow: black 1px 1px 3px;
}
.vjs-fullscreen .time {
  font-size: 18px;
}

.video-js {
  overflow: hidden;
}

.screenshot {
  margin-left: 6px;
}
.zoom {
  display: flex;
  font-size: 14px;
  padding: 1px;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.zoom-selected {
  cursor: pointer;
}
.zoom-slider {
  overflow: hidden;
  margin: auto 0px;
  width: 100px;
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(-100px);
  opacity: 0;
}
.quality-levels {
  position: relative;
}
.quality-menu {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  right: 0;
  bottom: 100%;
  border-radius: 2px;
}
.quality-menu ul {
  display: flex;
  flex-direction: column;
  padding: 0;
  justify-content: center;
}

.quality-menu li {
  padding: 5px;
  cursor: pointer;
  margin: 0;
  text-align: center;
  font-size: 18px;
}
.quality-menu li.active {
  background: rgba(255, 255, 255, 0.5);
}
.quality-menu li:hover {
  background: rgba(255, 255, 255, 0.2);
}
.slideUp-fade-enter-active {
  transition: all 0.1s ease-out;
}

.slideUp-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}

.slideUp-fade-enter-from,
.slideUp-fade-leave-to {
  transform: translateY(100px);
  opacity: 0;
}
</style>
