<template>
  <div class="cam-view">
    <div class="cam-view-wrap">
      <FileFrameName v-if="showfilename" @close="closefile" @save="savefile" />
      <CamPlayerArchive
        ref="video"
        :camName="camId"
        :showTimer="true"
        @screenshot="screenshot"
      />
    </div>
  </div>
</template>

<script>
import { useDefaultStore } from '@/store'
import CamPlayerArchive from '@/components/cam/CamPlayerArchive.vue'
import FileFrameName from '@/components/FileFrameName.vue'
import moment from 'moment'
export default {
  name: 'CamArchiveNew',
  components: {
    CamPlayerArchive,
    FileFrameName
  },
  setup() {
    const store = useDefaultStore()
    return { store }
  },
  computed: {
    videoSrc() {
      const uuid = this.store.currentDivision.guid
      const date = moment(this.date).format('YYYY_MM_D')
      return `//${this.url}/${uuid}/archive/${date}/${this.camId}/index.m3u8`
    },
    aspectRatio() {
      return this.$refs.video.aspectRatio
    }
  },
  data() {
    return {
      fullScreen: false,
      url:
        window.location.protocol == 'http:'
          ? this.store.currentDivision.address
          : this.store.currentDivision.url,
      allowedDays: [],
      range: { startTime: 0, endTime: 84399 },
      camId: this.$route.params.cam ? this.$route.params.cam : null,
      showfilename: false,
    }
  },
  async mounted() {
    if (this.store.currentDivision)
      this.store.setPageTitle(
        'Архив камеры: ' + this.store.currentDivision.name + ' / ' + this.camId
      )
    // this.dateFromCalendar = this.store.dateSave || moment().format()
  },
  methods: {
    savefile(fileName) {
      const frameUrl = this.screenData;
      console.log("frameUrl", frameUrl)
      const link = document.createElement('a')
      link.setAttribute('href', frameUrl)
      link.setAttribute('download', `${fileName}.jpg`)
      link.click()
      this.showfilename = false
    },
    closefile() {
      this.showfilename = false
    },
    screenshot(screenData) {
      this.screenData = screenData
      this.showfilename = true
    }
  }
}
</script>

<style scoped>
.cam-view {
  height: calc(100% - 50px);
  display: flex;
  flex-direction: column;
}
.cam-view-wrap {
  display: flex;
  margin-bottom: 1px;
  background-color: #000;
  justify-content: center;
  aspect-ratio: v-bind(aspectRatio);
  max-height: 100%;
}
.video-wrapper {
  width: 100%;
  height: 80%;
}
</style>
