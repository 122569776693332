<template>
  <div class="settings-block">
    <CFormSwitch v-model="logpassSwitch" label="Показать только логин и пароль" id="logpassSwitch"/>
  </div>
    <textarea 
      :value="text" 
    class="form-control readonly-textarea" 
    readonly
    @focus="$event.target.select()"
  ></textarea>
</template>

<script setup>
import { defineProps, computed, ref } from 'vue';
import { CFormSwitch } from '@coreui/bootstrap-vue';
const props = defineProps({
  data: {
    type: Array,
    required: true
  }
});
const logpassSwitch = ref(false);
const text = computed(() => {
    const copy = [...props.data];
    if (logpassSwitch.value) {
        return copy.map(e => e.split(':')[0] + ':' + e.split(':')[1]).join('\n')
    }
    return copy.join('\n')
    })
</script>

<style scoped>
.readonly-textarea {
  width: 100%;
  min-height: 300px;
  padding: 10px;
  border: 1px solid #666;
  font-family: monospace;
  resize: vertical;
  cursor: text;
}

.readonly-textarea:focus {
  outline: none;
  border-color: #389af0;
  box-shadow: 0 0 3px rgba(56, 154, 240, 0.3);
}
.form-switch {
    padding-left: 0;
}
</style>