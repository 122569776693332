<template>
  <transition
    name="expand"
    @enter="enter"
    @after-enter="afterEnter"
    @leave="leave"
  >
    <slot></slot>
  </transition>
</template>

<script setup>

const enter = (element) => {
  const width = getComputedStyle(element).width
  element.style.width = width
  element.style.position = 'absolute'
  element.style.visibility = 'hidden'
  element.style.height = 'auto'

  const height = element.offsetHeight
  element.style.width = null
  element.style.position = null
  element.style.visibility = null
  element.style.height = 0

  // Force repaint
  element.offsetHeight

  element.style.height = height + 'px'
}

const afterEnter = (element) => {
  element.style.height = 'auto'
}

const leave = (element) => {
  const height = element.offsetHeight
  element.style.height = height + 'px'

  // Force repaint
  element.offsetHeight

  element.style.height = 0
}
</script>

<style scoped>
.expand-enter-active,
.expand-leave-active {
  transition: height 0.3s ease-in-out;
  overflow: hidden;
}

.expand-enter-from,
.expand-leave-to {
  height: 0;
}
</style>
  