<template>
  <li>
    <component
      v-if="resolvedComponent"
      :is="resolvedComponent"
      :item="item"
      :isOpen="isOpen"
      :toggle="toggle"
      :open="open"
      :loadChildren="loadChildren"
      :children="children"
      :isLoading="isLoading"
      :refetchData="refetchData"
      :parent="selfRef"
    />
    <TransitionExpand>
      <div v-if="isOpen && hasChildren" class="children">
        <ul ref="observerTarget">
          <template v-if="childrenLength > 100">
            <TreeItem
              v-for="node in visibleChildren"
              :key="node.key"
              :item="node"
              :components="components"
              :lazy="lazy"
              :refetch="refetch"
              :parent="selfRef"
            />
          </template>
          <template v-else>
            <TreeItem
              v-for="node in children"
              :key="node.key"
              :item="node"
              :components="components"
              :lazy="lazy"
              :refetch="refetch"
              :parent="selfRef"
            />
          </template>
        </ul>
      </div>
    </TransitionExpand>
  </li>
</template>

<script setup>
import TransitionExpand from './TransitionExpand.vue'
import { inject, ref, computed, watch, shallowRef, onMounted, onBeforeUnmount, nextTick } from 'vue'
import { generateKeys } from './utils'

// Props definition with runtime validation removed for production
const props = defineProps({
  item: Object,
  components: Object,
  lazy: Object,
  refetch: Object,
  parent: Object
})

// Inject expanded keys
const expandedKeys = inject('treeKeys')

// Use shallowRef for better performance with large arrays
const defaultChildren = shallowRef(props.item.children ?? [])
const loadedChildren = shallowRef([])
const isLoading = ref(false)
const pageSize = 50 // Number of items to render initially
const currentPage = ref(1)

// Memoize component resolution
const resolvedComponent = computed(() => 
  props.components[props.item.type] || props.components['default']
)

// Optimize children computation
const children = computed(() => {
  if (loadedChildren.value.length === 0) return defaultChildren.value
  return [...defaultChildren.value, ...loadedChildren.value]
})

// Cache children length
const childrenLength = computed(() => children.value.length)

// Compute visible children for pagination
const visibleChildren = computed(() => {
  const start = 0
  const end = Math.min(currentPage.value * pageSize, children.value.length)
  return children.value.slice(start, end)
})

// Intersection observer for infinite scroll
const observerTarget = ref(null)
const observer = ref(null)
const selfRef = ref(null)

const createObserver = () => {
  if (observer.value) {
    observer.value.disconnect()
  }

  observer.value = new IntersectionObserver((entries) => {
    if (entries[0].isIntersecting) {
      const remainingItems = children.value.length - (currentPage.value * pageSize)
      if (remainingItems > 0) {
        currentPage.value++
      }
    }
  }, {
    rootMargin: '200px',
    threshold: 0
  })

  nextTick(() => {
    updateObserver()
  })
}

const updateObserver = () => {
  if (!observer.value || !observerTarget.value) return

  observer.value.disconnect()
  
  // Always observe the last currently visible item
  const lastVisibleIndex = (currentPage.value * pageSize) - 1
  const targetIndex = Math.min(lastVisibleIndex, children.value.length - 1)
  
  if (targetIndex >= 0) {
    const targetElement = observerTarget.value.children[targetIndex]
    if (targetElement) {
      observer.value.observe(targetElement)
    }
  }
}

// Ensure observer is updated when page changes
watch(currentPage, () => {
  nextTick(() => {
    updateObserver()
  })
}, { flush: 'post' })

onMounted(() => {
  createObserver();
  selfRef.value = {
    item: props.item,
    refetchData,
    toggle,
    open,
    loadChildren
  }
})

// Computed properties
const hasChildren = computed(() => {
  return defaultChildren.value.length > 0 || loadedChildren.value.length > 0
})

const isOpen = computed(() => !!expandedKeys[props.item.key])

// Methods
const loadChildren = async () => {
  const fetchFunction = props.lazy[props.item.type]
  if (!fetchFunction || loadedChildren.value.length !== 0 || isLoading.value) return false
  
  isLoading.value = true
  try {
    const result = await fetchFunction(props.item)
    loadedChildren.value = generateKeys(result, props.item.key, defaultChildren.value.length);
    createObserver();
    return true // Return true if children were loaded
  } catch (error) {
    console.error('Error loading children:', error)
    return false
  } finally {
    isLoading.value = false
  }
}

// Modify toggle to properly handle async state
const toggle = async () => {
  if (isLoading.value) return
  
  const willExpand = !expandedKeys[props.item.key]
  
  if (willExpand) {
    // Only try to load children if we're expanding
    await loadChildren()
    
    // Check if we have any children (either default or loaded)
    if (defaultChildren.value.length > 0 || loadedChildren.value.length > 0) {
      expandedKeys[props.item.key] = true
    }
  } else {
    // If we're collapsing, just update the state
    expandedKeys[props.item.key] = false
  }
}

// Modify open to follow the same pattern
const open = async () => {
  if (isLoading.value) return
  
  await loadChildren()
  
  if (defaultChildren.value.length > 0 || loadedChildren.value.length > 0) {
    expandedKeys[props.item.key] = true
  }
}

const refetchData = async () => {
  const refetchFunction = props.refetch[props.item.type]
  if (!refetchFunction || isLoading.value) return
  
  loadedChildren.value = []
  isLoading.value = true
  try {
    const result = await refetchFunction(props.item)
    defaultChildren.value = generateKeys(result.children, props.item.key)
  } finally {
    isLoading.value = false
  }
}

// Watch with flush: 'post' for better performance
watch(() => isOpen.value, async (newValue) => {
  if (newValue) {
    await loadChildren()
  }
}, { flush: 'post' })

// Add this watch to recreate observer when children change
watch([() => children.value.length, () => isOpen.value], () => {
  if (isOpen.value) {
    nextTick(() => {
      createObserver()
    })
  }
})

// Add this to reset currentPage when the node is collapsed
watch(() => isOpen.value, (newValue) => {
  if (!newValue) {
    currentPage.value = 1
  }
}, { flush: 'post' })

// Clean up
onBeforeUnmount(() => {
  if (observer.value) {
    observer.value.disconnect()
  }
})

// Expose methods for parent components
defineExpose({
  open,
  toggle,
  refetchData
})
</script>

<style scoped>
.children {
  margin-left: 20px;
  contain: content; /* Add CSS containment */
  transform: translateZ(0);
  will-change: transform; /* Optimize for animations */
}

ul {
  padding: 0;
  transform: translateZ(0);
  contain: content;
}

/* Add loading state styles */
.is-loading {
  opacity: 0.7;
  pointer-events: none;
}
</style>
