<template>
  <div class="download-list-item-device">
    <span class="name" :title="item.Name">{{ name }}</span>
    <span class="progress-container">
      <CProgress class="m-0">
        <CProgressBar :color="progressColor" :value="item.TranscodingProgress" class="progress-text">
            <span>{{ progressText }}</span>
            <span v-if="item.TranscodingProgress != 100" class="spinner-border spinner-border-sm"></span>
        </CProgressBar>
      </CProgress>
    </span>
    <span v-if="item.Status !== 'Completed'" class="download-checkbox">
        <input type="checkbox" :id="item.Uuid" v-model="downloadWhenReady" />
        <label :for="item.Uuid">Скачать при готовности</label>
    </span>
    <span v-else><CButton color="primary" @click="download()"><i class="mdi mdi-download"></i></CButton></span>
  </div>

</template>

<script setup>
import { computed, defineProps, ref, watch } from 'vue'
import { CProgress, CProgressBar, CButton } from '@coreui/bootstrap-vue'
import moment from 'moment'

const props = defineProps({
  item: Object,
  url: {
    type: String,
    default: ''
  },
})
const progressColor = computed(() => {
  if (props.item.Status.includes('Completed')) return 'success'
  if (props.item.Status.includes('Error')) return 'danger'
  return 'info'
});
const progressText = computed(() => {
  if (props.item.Status.includes('Completed')) return 'Готов'
  if (props.item.Status.includes('Error')) return props.item.Status
  return Math.round(props.item.TranscodingProgress) + '%'
});
const downloadWhenReady = ref(false);
const initStatus = ref(props.item.Status);

watch(() => props.item.Status, (newVal) => {
    if (newVal === 'Completed' && initStatus.value !== 'Completed' && downloadWhenReady.value) {
        download();
    }
})
const name = computed(() => {
    const cam = props.item.Name.split('_')[0];
    const date = moment(props.item.DateFrom).format('DD.MM.YYYY');
    const timeFrom = moment(props.item.DateFrom).format('HH:mm:ss');
    const timeTo = moment(props.item.DateTo).format('HH:mm:ss');
    return `${cam} ${date} ${timeFrom}-${timeTo}`;
});

function download() {
    const url = `${props.url}/downloads/${props.item.Name}?downloads=true`;
    const link = document.createElement('a')
    link.style.display = 'none'
    link.href = url
    link.download ="";
    // link.target = "_blank";

    // It needs to be added to the DOM so it can be clicked
    document.body.appendChild(link)
    link.click()

    // To make this work on Firefox we need to wait
    // a little while before removing it.
    setTimeout(() => {
      URL.revokeObjectURL(link.href)
      link.parentNode.removeChild(link)
    }, 0);
}
</script>

<style scoped>
.download-list-item-device {
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  justify-content: space-between;
  margin-bottom: 5px;
}
.name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  line-height: 31px;
}
.progress-container {
  width: 100%;
  margin: auto;
  padding: 0 10px;
}
.spinner-border-sm {
  min-width: 1rem;
  min-height: 1rem;
}

.bg-success {
  background-color: #57bb00 !important;
}
.progress-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
.download-checkbox input {
  position: absolute;
  left: -9999px;
  opacity: 0;
}
.download-checkbox label {
  white-space: nowrap;
  padding-left: 22px;
}

.download-checkbox label::before {
    margin-top: 3px;
}
</style>
