
import { defineStore } from 'pinia'
import { setSettingValue } from "./services/local-settings";
export const useDefaultStore = defineStore('default', {
  state: () => {
    return {
      pageTitle: 'TVC',
      isHideSidebar: false,
      currentUser: null,
      isAdmin: false,
      showCamName: false,
      showTimer: false,
      gridCam: 4,
      settings: null,
      overlay: false,
      loader: false,
      smallScreen: false,
      showGaps: false,
      fullScreen: false,
      dateSave: null,
      showUserToGroup: false,
      lastSelectedGroup: null,
      pageCam: [],
      camNames: [],
      serverVersion: '',
      favoriteList: [],
      currentDivision: null,
      allDivisions: [],
      showDivisionsToGroup: false,
      showAllUsers: false,
      showAddUser: false,
      lastSelectedUser: '',
      showUserEdit: false,
      newUserUserEdit: false,
      camOrder: {}
    }
  },
  actions: {
    setPageTitle(title) {
      this.pageTitle = title;
    },
    setShowGaps(v) {
      this.showGaps = v;
    },
    setServerVersion(value) {
      this.serverVersion = value;
    },
    setShowCamName(v) {
      this.showCamName = v;
      setSettingValue('interface.showCamName', v);
    },
    toggleShowCamName() {
      this.showCamName = !this.showCamName;
      setSettingValue('interface.showCamName', this.showCamName);
    },
    setShowTimer(v) {
      this.showTimer = v;
      setSettingValue('interface.showTimer', v);
    },

    setGridCam(grid) {
      this.gridCam = grid;
      setSettingValue('interface.gridCam', grid);
    },

    toggleSidebar() {
      this.isHideSidebar = !this.isHideSidebar;
      setSettingValue('interface.hideSidebar', this.isHideSidebar);
    },
    setHideSidebar(v) {
      this.isHideSidebar = v;
      setSettingValue('interface.hideSidebar', v);
    },
    setCamOrder(division, camOrder) {
      this.camOrder[division] = camOrder;
      setSettingValue('interface.camOrder2', this.camOrder);
    },
    setSmallScreen(value) {
      this.smallScreen = value;
    },

    setCurrentUser(user) {
      if (user) {
        this.currentUser = user;
        this.isAdmin = user.role === 1;
      } else {
        this.currentUser = null;
        this.isAdmin = false;
      }
    },
    setCurrentDivision(division) {
      this.currentDivision = division
    },
    setAllDivisions(divisions) {
      // divisions.forEach(d => {
      //   d.camList=d.camList.sort((a, b) => a.localeCompare(b));
      // })
      this.allDivisions = divisions
    },
    setAdminUser(user) {
      if (user) {
        this.isAdmin = user.role === 1;
      }
    },

    closeSidebar() {
      localStorage.sidebar = true;
      this.isHideSidebar = true;
    },

    setToken(token) {
      this.token = token;
    },

    overlayToggle(overlay) {
      this.overlay = overlay;
    },

    toggleLoader(loader) {
      this.loader = loader;
    },

    setFullScreen(fullScreen) {
      this.fullScreen = fullScreen;
    },

    setDateSave(date) {
      this.dateSave = date
    },

    setPageCam(pageCam) {
      this.pageCam = pageCam
    },

    setCamNames(camNames) {
      this.camNames = camNames
    },

    setFavoriteList(value) {
      this.favoriteList = value;
    },

    showUserToGroupModal(group) {
      this.lastSelectedGroup = group;
      this.showUserToGroup = true;
    },

    hideUserToGroupModal() {
      this.showUserToGroup = false;
      this.lastSelectedGroup = null;
    },

    showDivisionsToGroupModal(group) {
      this.lastSelectedGroup = group;
      this.showDivisionsToGroup = true;
    },

    hideDivisionsToGroupModal() {
      this.showDivisionsToGroup = false;
      this.lastSelectedGroup = null;
    },

    showAllUsersModal(group) {
      this.showAllUsers = true;
      this.lastSelectedGroup = group;
    },

    hideAllUsersModal() {
      this.showAllUsers = false;
      this.lastSelectedGroup = null;
    },

    showAddUserModal(group) {
      this.showAddUser = true;
      this.lastSelectedGroup = group;
    },

    hideAddUserModal() {
      this.showAddUser = false;
      this.lastSelectedGroup = null;
    },
    showUserEditModal(user, division, register = false) {
      this.showUserEdit = true
      this.lastSelectedUser = user
      this.lastSelectedGroup = division;
      this.newUserUserEdit = register
    },
    hideUserEditModal() {
      this.showUserEdit = false
      this.lastSelectedUser = ''
      this.lastSelectedGroup = null;
      this.newUserUserEdit = false
    }
  },
})
