<template>
  <div id="cam-list">
    <div class="grid-wrapper">
      <draggable
        v-if="!store.smallScreen"
        v-model="camOrder"
        item-key="id"
        class="cams-grid"
        id="grid"
        v-bind="dragOptions"
      >
        <template #item="{ element }">
          <div class="cam-container">
            <CamPlayerLivePreview
              :source="getCamSourceUrl(element)"
              class="cam"
              :key="element"
              :is-favorite="isInFavorite(element)"
              :show-controls-forced="store.showCamName"
              :show-timer="store.showTimer"
              :cam-name="element"
              @click-cam="routeToCam(element)"
              @toggle-favorite="toggleFavorite"
            />
          </div>
        </template>
      </draggable>
      <div v-else class="cams-grid">
        <div class="cam-container" v-for="element in camOrder" :key="element">
          <CamPlayerLivePreview
            :source="getCamSourceUrl(element)"
            class="cam"
            :is-favorite="isInFavorite(element)"
            :show-controls-forced="store.showCamName || store.smallScreen"
            :show-timer="store.showTimer"
            :cam-name="element"
            @click-cam="routeToCam(element)"
            @toggle-favorite="toggleFavorite"
          />
        </div>
      </div>
    </div>
    <Pagination
      v-if="!store.fullScreen"
      :currentPage="page"
      class="pagination-cam"
      :totalPages="pagesNum"
      @update:currentPage="updatePage"
    />
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import Pagination from '@/components/Pagination.vue'
import CamPlayerLivePreview from '@/components/cam/CamPlayerLivePreview.vue'
import { useDefaultStore } from '@/store'
import { Favorites } from '@/services/favorites'
export default {
  name: 'CamListNew',
  components: {
    draggable,
    Pagination,
    CamPlayerLivePreview
  },
  data() {
    return {
      page: 1
    }
  },
  created() {
    if (!this.division) {
      const firstDivision = this.store.allDivisions
        ?.sort((a, b) => {
          return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
        })
        ?.at(0).guid
      this.$router.push({ name: 'cam-listNew', params: { division: firstDivision } })
    }
    this.page = 1
    this.store.setCurrentDivision(this.division)
    this.store.setPageTitle(`Список камер / ${this.division?.name}`)
  },
  setup() {
    const store = useDefaultStore()
    return {
      store
    }
  },
  computed: {
    division() {
      return this.$route.params.division
        ? this.store.allDivisions.find((e) => e.guid === this.$route.params.division)
        : null
    },
    camList() {
      return this.division?.camList ?? []
    },

    dragOptions() {
      return {
        animation: 0,
        disabled: false,
        ghostClass: 'ghost'
      }
    },

    gridSize() {
      return this.store.gridCam
    },
    listEnd() {
      return this.page * this.gridSize ** 2
    },
    listStart() {
      return this.listEnd - this.gridSize ** 2
    },

    pagesNum() {
      return this.camList ? Math.ceil(this.camList.length / this.gridSize ** 2) : 0
    },
    currentPageCamsLength() {
      return this.camOrder.length
    },
    camOrder: {
      get() {
        if (!this.division) return this.camList;
        const list = this.store.camOrder ? this.store.camOrder[this.division.guid] ?? this.camList : this.camList;
        return list.slice(this.listStart, this.listEnd);
      },
      set(value) {
        const fullList = this.store.camOrder?.[this.division.guid] ?? [...this.camList];
        const beforeSlice = fullList.slice(0, this.listStart);
        const afterSlice = fullList.slice(this.listEnd);
        const newList = [...beforeSlice, ...value, ...afterSlice];
        this.store.setCamOrder(this.division.guid, newList);
      }
    }
  },
  methods: {
    updatePage(page) {
      this.page = page
    },
    onDragEnd(e) {
       const newIndex = e.newIndex + this.listStart;
       console.log(newIndex);
       const oldIndex = e.oldIndex + this.listStart;
      // console.log(newIndex, oldIndex);
       let camList = this.camList;
       const b = camList[oldIndex];
       camList.splice(oldIndex, 1);
       camList.splice(newIndex, 0, b);
      // const b = camList[newIndex];
      // camList[newIndex] = camList[oldIndex];
      // camList[oldIndex] = b;
      this.store.setCamOrder(this.division.guid, camList);
      // console.log(e.oldIndex, e.newIndex, oldIndex, newIndex)
    },
    isInFavorite(cam) {
      const res = this.store.favoriteList?.find(
        (e) => e.division === this.division.guid && e.cam === cam
      )
      return res !== undefined
    },

    async toggleFavorite(cam) {
      try {
        if (this.isInFavorite(cam)) {
          await Favorites.remove({
            division: this.store.currentDivision.guid,
            cam
          })
          this.$toast.success('Камера удалена из избранного')
          return
        }
        await Favorites.add({
          division: this.store.currentDivision.guid,
          cam
        })
        this.$toast.success('Камера добавлена в избранное')
      } catch (e) {
        console.log(e)
      }
    },
    routeToCam(cam) {
      this.$router.push({
        name: 'cam-new',
        params: { cam: cam, division: this.store.currentDivision.guid }
      })
    },
    getCamSourceUrl(cam) {
      if (window.location.protocol == 'http:')
        return this.division
          ? `http://${this.division.address}/${this.division.guid}/${cam}/m/index.m3u8`
          : undefined
      return this.division
        ? `https://${this.division.guid}.pervaya1.ru/${this.division.guid}/${cam}/m/index.m3u8`
        : undefined
    }
  }
}
</script>
<style scoped>
#cam-list {
  height: 100%;
  overflow: hidden;
}
.grid-wrapper {
  height: calc(100% - 115px);
}
.cams-grid {
  display: grid;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  grid-template-columns: repeat(v-bind(gridSize), 1fr);
  grid-template-rows: repeat(v-bind(gridSize), 1fr);
}
.page-container_fullscreen .cams-grid {
  height: 100%;
}
.cam-container {
  width: 100%;
}
.pagination-cam {
  margin: 10px auto;
}
.cam {
  padding: 1px;
  background: #383e47;
}
.main-content_fullscreen .grid-wrapper {
  height: calc(100%);
}
.ghost {
  border-left: 6px solid rgb(0, 183, 255);
  box-shadow: 10px 10px 5px -1px rgba(0, 0, 0, 0.14);
  opacity: 0.7;
}
@media screen and (max-width: 768px) {
  .cams-grid {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(v-bind(currentPageCamsLength), 1fr);
    overflow: auto;
  }
  .cam-container {
    min-height: 300px;
  }
  .grid-wrapper {
    height: calc(100% - 60px);
  }
}
</style>
