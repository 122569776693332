<template>
  <div id="wrapper" :class="{ 'sidebar-collapse': isHideSidebar }">
    <div class="page-container" :class="{ 'page-container_fullscreen': fullScreen }">
      <main-header v-if="!fullScreen"></main-header>
      <div class="main-content" :key="$route.params.cam" :class="{ 'main-content_fullscreen': fullScreen }">
        <page-header v-if="showPageHeader"></page-header>

        <button v-else class="main-content__exit-fullscreen btn btn-dark btn-rounded" type="button"
                @click="closeFullscreen">
          <i class="mdi mdi-fullscreen"></i>
        </button>
        <router-view v-slot="{ Component }" v-if="store.currentUser" :key="$route.params.division">
          <transition name="fade" mode="out-in">
            <component :is="Component" />
          </transition>
        </router-view>
      </div>
      <main-footer v-if="!fullScreen"></main-footer>
    </div>

    <sidebar v-if="!fullScreen" />
  </div>
</template>

<script lang="js">
import User from '../services/user';
import Sidebar from '../components/Sidebar/Sidebar.vue';
import MainHeader from '../components/Header.vue';
import PageHeader from '../components/PageHeader.vue';
import MainFooter from '../components/Footer.vue';
import { useDefaultStore } from '@/store';

export default {
  name: 'Cams',
  components: {
    Sidebar,
    MainHeader,
    MainFooter,
    PageHeader
  },

  data: () => ({
    currentUser: null,
  }),
  setup() {
    const store = useDefaultStore();
    return {
      store
    };
  },
  computed: {
    isHideSidebar() {
      return this.store.isHideSidebar;
    },

    showPageHeader() {
      return (!this.fullScreen && (this.store.currentDivision) && (this.$route.name !== 'server-info')) || this.$route.name === 'favorite';
    },

    smallScreen() {
      return this.store.smallScreen;
    },

    fullScreen() {
      return this.store.fullScreen;
    },

    /**
     * Если надо показать все камеры в режиме архива
     */
    allMode() {
      return !!this.$route.params.all;
    }
  },

  beforeCreate() {
    User.checkUser();
  },

  async created() {
    //await this.getDivisions();
    await this.getUser();
    const deepCloned = [...this.store.allDivisions];
    deepCloned.sort((a, b) => {
      if (a.address == null || b.address == null) return -1;
      const smallA = String(a.name).toLowerCase();
      const smallB = String(b.name).toLowerCase();
      if (smallA < smallB) {
        return -1;
      }
      if ((smallA > smallB)) {
        return 1;
      }
      return 0;
    })
    const firstItem = deepCloned.find(el => el.address != null);
    if (firstItem && (this.$route.name === undefined || (this.$route.name === "cam-listNew" && this.$route.params.division == ""))) this.$router.push({ name: 'cam-listNew', params: { division: firstItem.uuid } });
  },

  async mounted() {
    window.addEventListener('keydown', this.closeFullscreenOnEsc);
  },

  beforeUnmount() {
    window.removeEventListener('keydown', this.closeFullscreenOnEsc);
  },

  methods: {

    setCurrentDivision(currentDivision) {
      this.store.setCurrentDivision(currentDivision)
      this.store.setPageCam(currentDivision.cameras)
    },
    async getUser() {
      this.store.setCamNames(this.store.allDivisions)
    },

    closeSidebar() {
      this.store.closeSidebar();
    },

    closeFullscreen() {
      this.store.setFullScreen(false);
    },

    closeFullscreenOnEsc(event) {
      if (event.key === 'Escape') {
        this.closeFullscreen();
      }
    }
  }

};
</script>

<style lang="stylus">

.sidebar-overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}

.main-content {
  &__exit-fullscreen {
    position: absolute;
    top: 0.5rem;
    right 0.5rem;
    z-index 99;

    font-size: 2rem;
    line-height 1;
    color: #666;
    padding: 0;
    background: transparent;
  }
}
</style>
