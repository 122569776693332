<template>
    <div class="download-location-select">
        <button v-if="showDevice" @click="nextStep('device')" class="btn btn-primary download-location-select__item">
            <i class="mdi" :class="isMobile() ? 'mdi-cellphone-arrow-down-variant' : 'mdi-monitor-arrow-down-variant'"></i>
            <span>Устройство</span></button>
        <button v-if="showCloud" @click="nextStep('cloud')" class="btn btn-primary download-location-select__item"><i class="mdi mdi-cloud"></i><span>Облако</span></button>
        <button @click="showDownloadsList" class="btn btn-primary download-location-select__item"><i class="mdi mdi-list-box-outline"></i><span>Список загрузок</span></button>
    </div>
</template>

<script setup>
import { useDefaultStore } from '@/store';
import { computed, ref, inject } from 'vue';
import { useRoute } from 'vue-router';
import { isMobile } from '@/helpers/utils';
import IntervalDownload from './IntervalDownload.vue';
import DownloadsList from './DownloadsList.vue';
const store = useDefaultStore();
const route = useRoute();
const { showModal } = inject('modal');
const showDevice = ref(true);
const showCloud = computed(() => store.isAdmin);

const props = defineProps({
    range: {
        type: Object,
        default: () => {}
    },
    date: {
        type: Date,
        default: () => new Date()
    }
});

const division = computed(() => store.currentDivision);
const cam = computed(() => route.params.cam);

function nextStep(to) {
    showModal(IntervalDownload, {
        type: to,
        division: division.value,
        cam: cam.value,
        date: props.date,
        range: props.range
    }, {}, "Скачать архив")
}

function showDownloadsList() {
    showModal(DownloadsList, {}, {}, "Список скачиваний", 'lg')
}
</script>
<style scoped>
.download-location-select {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;
}
.download-location-select__item {
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
    align-items: center;
    min-width: 127px;
}
.download-location-select__item i {
    font-size: 24px;
}
</style>
